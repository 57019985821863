import Icon from '@mdi/react'
import { Box, Button, ListItem } from '@mui/material'
import { Link } from 'react-router-dom'

// TODO : déplacer ce widget dans le fichier SideNav.tsx
// TODO : typer fortement les arguments
const NavLink = ({ changeUri, name, mdiIcon, self_uri, current_uri }: any) => {
    return (
        <ListItem sx={{ p: 0, py: 1 }}>
            <Box sx={{ width: '100%' }}>
                <Link
                    to={self_uri}
                    onClick={(_event) => {
                        changeUri(self_uri)
                    }}
                    style={{
                        color: '#FFFFFF',
                        textDecoration: 'none',
                        width: '100%',
                        display: 'block',
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor:
                                self_uri === current_uri
                                    ? 'primary.main'
                                    : '#ffffff',
                            color:
                                self_uri === current_uri
                                    ? '#ffffff'
                                    : 'text.primary',
                            justifyContent: 'left',
                            boxShadow: 'none',
                            px: 2,
                            '&:hover': {
                                backgroundColor:
                                    self_uri === current_uri
                                        ? 'primary.dark'
                                        : '#eeeeee',
                                boxShadow: 'none',
                                color:
                                    self_uri === current_uri
                                        ? '#ffffff'
                                        : 'primary.main',
                            },
                        }}
                    >
                        <Icon className="app_svg" path={mdiIcon} />
                        <span style={{ paddingLeft: '20px' }}>{name}</span>
                    </Button>
                </Link>
            </Box>
        </ListItem>
    )
}

export default NavLink
