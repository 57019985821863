import { Router } from './components/Router/Router'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './assets/styles/animation.css'
import { createTheme, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import { teal } from '@mui/material/colors'

const theme = createTheme({
    palette: {
        primary: {
            main: teal[700],
        },
        secondary: {
            main: '#F33F3F',
        },
    },
    typography: {
        fontFamily: [
            'ui-sans-serif',
            'system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Noto Color Emoji"',
        ].join(','),
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100vh', color: 'text.primary' }}>
                <Router />
            </Box>
        </ThemeProvider>
    )
}

export default App
