import { Box, Button, Modal, Paper, TextField } from '@mui/material'
import { useState } from 'react'

// TODO : typer fortement les arguments
// TODO : supprimer le dossier parent de ce fichier
// TODO : supprimer l'export default

const TextInputModale = ({ message, close, action, reload }: any) => {
    const [data, setData] = useState('')

    const saveData = (value: string) => {
        setData(value)
    }

    const submitInput = async (event: any) => {
        event.preventDefault()
        await action(data)
        await reload()
        close()
    }

    return (
        <Modal open={true}>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#0020003f',
                    backdropFilter: 'blur( 5px )',
                    WebkitBackdropFilter: 'blur( 5px )',
                }}
                display="flex"
                position="fixed"
            >
                <Box
                    component={Paper}
                    sx={{
                        p: 3,
                        border: 1,
                        borderColor: 'primary.light',
                        maxWidth: 0.2,
                    }}
                    id="info-popup"
                >
                    <div>
                        <h3>{message}</h3>
                    </div>
                    <form
                        onSubmit={(event) => {
                            submitInput(event)
                        }}
                    >
                        <TextField
                            onChange={(event) => {
                                saveData(event.target.value)
                            }}
                            variant="standard"
                            sx={{ my: 2 }}
                            label="nom"
                        />
                        <Box display="flex">
                            <Button
                                value="Continuer"
                                type="submit"
                                variant="contained"
                            >
                                Continuer
                            </Button>
                            <Button
                                sx={{
                                    mx: 2,
                                }}
                                color="inherit"
                                onClick={() => {
                                    close()
                                }}
                                id="close-modal"
                                type="button"
                                variant="outlined"
                            >
                                Annuler
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Modal>
    )
}

export default TextInputModale
