import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import logo_cnpf from '../../assets/images/logo_bioclimsol.png'

const Home = () => {
    return (
        <Box
            sx={{
                width: 1,
                height: 0.7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box>
                <img
                    style={{ maxWidth: '100%' }}
                    src={logo_cnpf}
                    alt="Cnpf Logo"
                    className="home-img"
                />
                <Typography variant="h3" sx={{ fontWeight: 600 }}>
                    Bienvenue sur l'interface d'administration !
                </Typography>
                <Typography
                    variant="h5"
                    sx={{ color: 'text.secondary', py: 2 }}
                >
                    Veuillez utiliser le menu sur la gauche pour accéder aux
                    différentes sections de l'application.
                </Typography>
            </Box>
        </Box>
    )
}

export default Home
