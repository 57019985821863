import { AlgosChangelog } from '../interfaces/algoschangelog_interface'
import { ApiClient } from './api_client'

export class AlgosChangelogApi {
    /**
     * Récupère et renvoie de la base la liste des changelogs des versions des algorithmes
     * @returns AlgosChengelog[]
     */
    static async getAlgosChangelog(): Promise<AlgosChangelog[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/config/algos_changelog')
        } catch (e) {
            throw new Error(
                `Echec du téléchargement de la liste des versions des algorithmes`
            )
        }
    }

    // TODO : fonction inutilisée, non ?
    /**
     * Supprime le changelog correspondant à l'id en base
     * @param id
     */
    static async deleteAlgosChangelog(id: number | string) {
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.delete(`/web/algos_changelogs/${id}`)
        } catch (e) {
            throw new Error(
                `Echec de la suppression du changelog d'algorithme d'id : ${id}`
            )
        }
    }

    // TODO : à priori cette fonction n'est jamais utilisée, à investiger
    // REMY => un nouveau changelog est enregistré en passant par updateAlgosChangelog...
    /**
     * Enregistre une nouveau AlgoChangelog en base de donnée
     * @param log
     */
    static async createAlgosChangelog(algos_changelog: AlgosChangelog) {
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.post(`/web/algos_changelogs`, {
                body: JSON.stringify(algos_changelog),
            })
        } catch (e) {
            throw new Error(
                `Echec de la création d'un nouveau changelog d'algorithme`
            )
        }
    }

    /**
     * Enregistre les modifications de l'algoChangelog dans la base de donnée
     * @param log
     */
    static async updateAlgosChangelog(algos_changelog: AlgosChangelog[]) {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.post('/config/algos_changelog', {
                body: JSON.stringify(algos_changelog),
            })
        } catch (e) {
            throw new Error(
                `Echec de la mise à jour de la liste de changelog d'algorithme`
            )
        }
    }
}
