import { Box } from '@mui/material'
import React from 'react'
import { UserProjects } from './user_projects'
import { ProjectApi } from '../../api/projects_api'
import { Project } from '../../interfaces/project_interface'
import { User } from '../../interfaces/user_interface'
import { UserApi } from '../../api/users_api'
import { AllProjects } from './all_projects'

export interface ExportProps {}

interface ExportState {
    projects: Project[]
    adminProjects?: Project[]
    user?: User
}

export class Export extends React.Component<ExportProps, ExportState> {
    constructor(props: ExportProps) {
        super(props)
        this.state = {
            projects: [],
            adminProjects: [],
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            await this.getRoute()
        } catch (e) {
            throw new Error('Echec du chargement du composant Export')
        }
    }

    async getRoute() {
        try {
            let projects = await ProjectApi.listUserProjects()
            const user = await UserApi.getInfoCurrentUser()

            if (user.isAdmin) {
                const adminProjects = await ProjectApi.allProjects()
                this.setState({
                    adminProjects: adminProjects,
                })
            }
            this.setState({
                projects: projects,
                user: user,
            })
        } catch (e) {
            throw new Error(
                "échec du téléchargement de la liste des projet de l'utilisateur"
            )
        }
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <h1>Export des Projets</h1>
                <UserProjects projects={this.state.projects} />
                {this.state.user?.isAdmin ? (
                    <AllProjects projects={this.state.adminProjects!} />
                ) : (
                    <></>
                )}
            </Box>
        )
    }
}
