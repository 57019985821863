import { mdiDelete, mdiDownload } from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Box,
    LinearProgress,
    Modal,
    Typography,
} from '@mui/material'
import { filesize } from 'filesize'
import * as React from 'react'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { RasterApi } from '../../api/rasters_api'
import { Raster } from '../../interfaces/raster_interface'

interface RastersTableProps {
    rasters: Raster[]
    onRequestReload: () => void
}

interface RastersTableState {
    askDelete: boolean
    current_raster?: Raster
    downloading: boolean
    downloadProgress: number
}

export class RastersTable extends React.Component<
    RastersTableProps,
    RastersTableState
> {

    constructor(props: RastersTableProps) {
        super(props)
        this.state = {
            askDelete: false,
            downloading: false,
            downloadProgress: 0,
        }
    }

    submitDownloadRaster = async (raster: Raster) => {
        this.setState({ downloading: true, downloadProgress: 0 })
    
        try {
            
            const download = await RasterApi.downloadRaster(
                raster,
                (progress: number) => {
                    this.setState({ downloadProgress: progress })
                }
            )
            const blobUrl = URL.createObjectURL(download)
            const link = document.createElement('a')
            link.href = blobUrl
            link.download = raster.fileName.split('@')[1]
            link.click()
            URL.revokeObjectURL(blobUrl)
        } catch (error) {
            // Gérer l'erreur de téléchargement du raster
            console.error(error)
        } finally {
            this.setState({ downloading: false, downloadProgress: 0 })
        }
    }
    

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'primary.main' }}>
                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    #
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }}>
                                    Nom du fichier
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }}>
                                    Nom de la variable
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    Commentaire
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    Date de mise en ligne
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    Poids
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    Beta
                                </TableCell>

                                <TableCell sx={{ color: '#ffffff' }} align={'center'}>
                                    Actif
                                </TableCell>

                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {GeneralFunctionsApi.SortByID(
                                this.props.rasters
                            ).map((raster, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ py: 0 }}  align={'center'}>
                                        {raster.id}
                                    </TableCell>

                                    <TableCell sx={{ py: 0 }}>
                                        {raster.fileName.split('@')[1]}
                                    </TableCell>

                                    <TableCell>{raster.fileName.split('@')[0]}</TableCell>

                                    <TableCell  align={'center'}>{raster.comment}</TableCell>

                                    <TableCell sx={{ py: 0 }}  align={'center'}>
                                        {new Date(
                                            raster.uploadTime * 1000 // passage en millisecondes
                                        ).toLocaleDateString()}
                                    </TableCell>

                                    <TableCell sx={{ py: 0 }}  align={'center'}>
                                        {filesize(Number(raster.fileSize), {
                                            base: 2,
                                            standard: 'jedec',
                                        }).toString()}
                                    </TableCell>

                                    <RasterBooleanCell
                                        align={'center'}
                                        boolean={raster.betaUse}
                                        onClick={() => {
                                            // TODO : déplacer une méthode de classe
                                            RasterApi.toggleRasterBeta(raster)
                                            setTimeout(() => {this.props.onRequestReload()}, 100)
                                        }}
                                    />

                                    <RasterBooleanCell
                                        align={'center'}
                                        boolean={raster.inUse}
                                        onClick={() => {
                                            RasterApi.toggleRasterActive(raster)
                                            setTimeout(() => {this.props.onRequestReload()}, 100)
                                        }}
                                    />

                                    <TableCell sx={{ py: 0 }} align={'center'}>
                                        <List>
        
                                        {raster.inUse || raster.betaUse ? (
                                            <></>
                                        ) : (

                                            <ListItem sx={{ py: 0, m: 1 }}>
                                                <Tooltip
                                                    title="Supprimer"
                                                    placement="right"
                                                >
                                                    <Button
                                                        color="error"
                                                        sx={{ py: 0 }}
                                                        variant="outlined"
                                                        onClick={() => {this.setState({
                                                                current_raster: raster,
                                                                askDelete: true,
                                                            })
                                                        }}
                                                    >
                                                        <Icon path={mdiDelete}/>
                                                    </Button>
                                                </Tooltip>
                                            </ListItem>
                                            )}

                                            <ListItem sx={{ py: 0, m: 1 }}>
                                                <Tooltip
                                                    title="Télécharger"
                                                    placement="right"
                                                >
                                                    <Button
                                                        color="info"
                                                        sx={{ py: 0 }}
                                                        variant="outlined"
                                                        onClick={() => this.submitDownloadRaster(raster)}
                                                    >
                                                        <Icon path={mdiDownload}/>
                                                    </Button>
                                                </Tooltip>
                                            </ListItem>
                                        </List>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                
                {this.state.askDelete ? (
                    <Areyousure
                        message="Supprimer un raster"
                        close={() => {
                            this.setState({ askDelete: false })
                        }}
                        reload={() => {
                            setTimeout(() => {this.props.onRequestReload()}, 100)
                        }}
                        action={() => {
                            RasterApi.deleteRaster(this.state.current_raster!)
                        }}
                    />
                ) : (
                    <></>
                )}


                 <Modal open={this.state.downloading}>
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backdropFilter: 'blur( 5px )',
                                WebkitBackdropFilter: 'blur( 5px )',
                            }}
                            display="flex"
                            position="fixed"
                        >
                            {this.state.downloadProgress < 100 ? (
                                <Box sx={{ width: 0.3, textAlign: 'center' }}>
                                    <Typography sx={{ color: '#ffffff', pb: 2 }}>
                                        Chargement du fichier raster{' '}
                                        {this.state.downloadProgress.toFixed(0)}%
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={this.state.downloadProgress}
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ width: 0.3, textAlign: 'center' }}>
                                    <Typography sx={{ color: '#ffffff', pb: 2 }}>
                                        Téléchargement du fichier raster
                                    </Typography>
                                    <LinearProgress />
                                </Box>
                            )}
                        </Box>
                </Modal>
            </>
        )
    }
}

function RasterBooleanCell(props: {
    boolean: boolean
    onClick: () => void
    align: "inherit" | "left" | "center" | "right" | "justify" | undefined
}) {
    return (
        <TableCell sx={{ py: 0 }}  align={props.align}>
            <Tooltip title={props.boolean ? 'Désactiver' : 'Activer'}>
                <Button color="inherit" onClick={props.onClick}>
                    {props.boolean ? 'OUI' : 'NON'}
                </Button>
            </Tooltip>
        </TableCell>
    )
}
