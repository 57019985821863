import { GeneralFunctionsApi } from '../api/general_functions_api'

interface CoordinatesXY {
    lon: number
    lat: number
}

export interface CoordinatesSquare {
    min: CoordinatesXY
    max: CoordinatesXY
}

export interface TilesConfiguration {
    url: string
    defaultCenter: CoordinatesXY
    extent: CoordinatesSquare
}

// TODO : cette variable ne devrait pas être exposée
// export let TILES_DATA: TilesConfiguration

export async function getTilesConfiguration(): Promise<TilesConfiguration> {
    // TODO : le fetch ne devrait avoir lieu qu'une seule fois, lorsque TILES_DATA = undefined
    let TILES_DATA = await GeneralFunctionsApi.fetchTilesUrl()
    console.log(TILES_DATA)
    return TILES_DATA
}

// TODO : cet appel de fonction n'a rien à faire ici
// getTilesConfiguration()
