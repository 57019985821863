import { Credit } from '../interfaces/credit_interface'
import { ApiClient } from './api_client'

export class CreditApi {
    /**
     * Fetch la liste des credits du backend
     */
    static async listCredits(): Promise<Credit[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/config/credits')
        } catch (e) {
            throw new Error(`Echec de la récupération de la liste des crédits`)
        }
    }

    /**
     * Sauvegarde l'icon en base
     * @param id
     * @param icon
     */
    // TODO : cette fonction semble inutilisée...
    static async updateCreditIcon(id: string, icon: string) {
        const body = {
            credit: {
                icon: icon,
            },
        }
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.patch(`/web/credits/${id}`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec de la mise à jour de l'icône du crédit id : ${id}`
            )
        }
    }

    /**
     * Détruit une entré crédit en base de donnée
     * @param id
     */
    // TODO : cette fonction semble inutilisée...
    static async deleteCredit(id: string) {
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.delete(`/web/credits/${id}`)
        } catch (e) {
            throw new Error(`Echec de la suppression du crédits id : ${id}`)
        }
    }

    /**
     * Supprime la liste actuelle de crédits dans le backend et la remplace par la nouvelle
     * @param credits
     */
    static async replaceCreditList(credits: Credit[]) {
        // TODO : supprimer ce try / catch et cette variable body
        const body = credits
        try {
            await ApiClient.post('/config/credits', {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(`Echec de la mise à jour de la liste des crédits`)
        }
    }
}
