import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { AuthToken } from '../../api/token_api'
import { ApiClient } from '../../api/api_client'

export interface OpenIDCallBackProps {
    params?: any
}

interface OpenIDCallBackState {
    error: boolean
}

export class OpenIdCallback extends React.Component<
    OpenIDCallBackProps,
    OpenIDCallBackState
> {
    constructor(props: OpenIDCallBackProps) {
        super(props)
        this.state = {
            error: false,
        }
    }

    async componentDidMount(): Promise<void> {
        await this.load()
    }

    async load() {
        console.log('Init OpenIDCallBack')
        await this.completeOpenIdTransaction()
    }

    async completeOpenIdTransaction() {
        const query = window.location.search
        const urlParams = new URLSearchParams(query)
        try {
            const response = await ApiClient.post(`/auth/finish_openid_login`, {
                body: JSON.stringify({
                    code: urlParams.get('code'),
                    state: urlParams.get('state'),
                }),
                restricted: false,
            })
            AuthToken.set(response.token)
            window.location.href = '/'
        } catch (e) {
            console.error(e)
            this.setState({
                error: true,
            })
        }
    }

    render() {
        return (
            <>
                {!this.state.error ? (
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '50px',
                            fontWeight: 'bold',
                        }}
                    >
                        <h1>Echec de l'authentification OpenID</h1>
                        <p>
                            Etes-vous bien autorisé à utiliser cette application
                            ?
                        </p>
                        <Link to="/">Retour à l'écran d'accueil</Link>
                    </div>
                )}
            </>
        )
    }
}
