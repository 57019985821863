import { AuthToken } from './token_api'
import { Raster, NewRasterData } from '../interfaces/raster_interface'
import { get_restricted_url } from '../global_variables/backend_url_api'
import { ApiClient } from './api_client'

export class RasterApi {
    /**
     * Mise en ligne d'un nouveau fichier raster
     * Prends en paramètres un fichier, de préférence un fichier.tif, ainsi qu'une fonction permettant l'affichage de de la progession de l'upload
     * @param raster_data
     * @param onProgress
     */
    static async uploadRaster(
        raster_data: NewRasterData,
        onProgress: (eEvent: any) => void
    ) {
        return new Promise((res, rej) => {
            let request = new XMLHttpRequest()
            let fd = new FormData()
            fd.append('raster', raster_data.file!)
            fd.append('comment', raster_data.comment)
            fd.append('varname', raster_data.varname)
            request.upload.onprogress = onProgress
            request.onreadystatechange = function () {
                if (request.readyState === 4) {
                    if (request.status >= 200 && request.status <= 299) {
                        res(null)
                    } else if (request.status === 409) {
                        rej(
                            'Echec de la mise en ligne du raster car un fichier identique ou un fichier possédant le même nom est présent en base de donnée'
                        )
                    } else {
                        rej('Echec de la mise en ligne du fichier raster')
                    }
                }
            }
            request.open('POST', `${get_restricted_url()}/maps/rasters/upload`)
            request.setRequestHeader('Authorization', AuthToken.get())
            request.send(fd)
        })
    }

    /**
     * Téléchargement d'un fichier raster
     * @param raster
     * @param onProgress
     */
    static async downloadRaster(
        raster: Raster,
        onProgress: (progress: number) => void
    ): Promise<Blob> {
        return new Promise<Blob>((resolve, reject) => {
            const request = new XMLHttpRequest()
            request.open(
                'GET',
                `${get_restricted_url()}/maps/rasters/download/${raster.sha512}`
            )
            request.setRequestHeader('Authorization', AuthToken.get())
            request.responseType = 'blob'

            request.onload = () => {
                if (request.status === 200) {
                    resolve(request.response)
                } else {
                    reject(new Error('Echec du téléchargement du Raster'))
                }
            }

            request.onprogress = (event) => {
                const progress = (event.loaded / event.total) * 100
                onProgress(progress)
            }
            request.send()
        })
    }

    /**
     * Cette fonction retourne la liste des données des fichiers rasters uploadés dans le backend
     * @returns Raster[]
     */
    static async listRasters(): Promise<Raster[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/maps/rasters/list')
        } catch (e) {
            throw new Error(`Echec du chargement de la liste des rasters`)
        }
    }

    /**
     * Supprime dans le backend les données liés au fichier raster uploadé ainsi que le fichier raster en lui-même
     * @param raster
     */
    static async deleteRaster(raster: Raster) {
        await ApiClient.delete(`/maps/rasters/${raster.id}`)
    }

    /**
     * Inverse le boolean gérant l'état actif d'un fichier raster
     * @param raster
     */
    static async toggleRasterActive(raster: Raster) {
        await ApiClient.put(`/maps/rasters/set_enable/${raster.id}`, {
            body: JSON.stringify({ inUse: !raster.inUse }),
        })
    }

    /**
     * Inverse le boolean gérant l'état beta d'un fichier raster
     * @param raster
     */
    static async toggleRasterBeta(raster: Raster) {
        await ApiClient.put(`/maps/rasters/set_enable/${raster.id}`, {
            body: JSON.stringify({
                betaUse: !raster.betaUse,
            }),
        })
    }
}
