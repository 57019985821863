import {User} from "../../interfaces/user_interface";
import React from "react";
import {StructureApi} from "../../api/structures_api";
import {
    Box, Button, List, ListItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {UserApi} from "../../api/users_api";
import {GeoRestrictionApi} from "../../api/geo_restrictions_api";
import Icon from "@mdi/react";
import {mdiLogout} from "@mdi/js";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";

export interface ProfilsProps {
    current_user: number
}

interface ProfilState {
    structures: any[] // TODO : corriger le typage
    user: User
    zones: any[]
    action_user_id: number
    delete: boolean
}

export class Profil extends React.Component<ProfilsProps, ProfilState> {
    constructor(props: ProfilsProps) {
        super(props)
        this.state = {
            user: {
                id: 0,
                firstname: '',
                lastname: '',
                email: '',
                maillingAddress: '',
                phoneNumber: '',
                isAdmin: false,
                isBetaTest: false,
                isCnpf: false,
                isActive: true,
                numDevicesAuthorized: 0,
                structureRole: 'UNIQUE',
                expireOn: new Date().getTime() / 1000,
                structureId: 1,
                devices: [],
            },
            structures: [],
            zones: [],
            action_user_id: NaN,
            delete: false
        }
        this.getStructures = this.getStructures.bind(this)
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            const user = await UserApi.getUser(this.props.current_user)
            const structures = await this.getStructures()
            const zoneList = await GeoRestrictionApi.listGeoRestrictions()
            this.setState({
                user: user,
                structures: structures,
                zones: zoneList
            })
        } catch (e) {
            console.error(e)
            alert("Echec du chargement de l'utilisateur")
        }
    }

    async getStructures() {
        const structureList = await StructureApi.listStructures()
        return structureList.map((structure: any) => {
            return {name: structure.name, value: structure.id}
        })
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <Box sx={{borderBottom: 1, mb: 2}}>
                    <h2>Mon profil</h2>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{pt: 2, minWidth: '30%'}}>
                        <Box sx={{pb: 2}}>
                            <h4>Informations générales</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            backgroundColor: '#efffef',
                            borderRadius: 1
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Nom:</span> <span>{this.state.user.lastname.toUpperCase()}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Prénom:</span> <span>{this.state.user.firstname}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Email:</span> <span>{this.state.user.email}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                <span>N° de Téléphone:</span> <span>{this.state.user.phoneNumber}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Adresse Postale:</span> <span>{this.state.user.maillingAddress}</span>
                            </Box>
                            {this.state.user.isCnpf ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Membre CNPF:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Membre CNPF:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.user.isActive ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Compte Actif:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Compte Actif:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.user.isBetaTest ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Compte Beta-Test:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Compte Beta-Test:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.user.isAdmin ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Rôle:</span> <span>Administrateur</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Rôle:</span> <span>Utilisateur</span>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{pt: 2, pl: 10, minWidth: '25%'}}>
                        <Box sx={{pb: 2}}>
                            <h4>Licence</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            backgroundColor: '#efffef',
                            borderRadius: 1
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Date d'expiration:</span>
                                <span>{new Date(this.state.user.expireOn * 1000).toLocaleDateString()}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Nombre d'appareils autorisés:</span>

                                {(this.state.user.devices?.length && (this.state.user.devices?.length >= this.state.user.numDevicesAuthorized)) ? (
                                    <span style={{ color: 'red' }}>{this.state.user.numDevicesAuthorized}</span>
                                ) : (
                                    <span>{this.state.user.numDevicesAuthorized}</span>
                                )}

                            </Box>
                            <Box>
                                <TableContainer component={Paper} sx={{marginTop: 5}}>
                                    <Table>
                                        <TableHead
                                            sx={{
                                                backdropFilter: 2,
                                                backgroundColor: 'primary.main',
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell sx={{color: '#ffffff'}}>
                                                    Nom de l'appareil
                                                </TableCell>
                                                <TableCell sx={{py: 0, textAlign: 'center',color: '#ffffff'}}>
                                                    Dernière utilisation
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {!(this.state.user.devices?.length) ? (
                                                <TableRow sx={{color: '#ffffff'}}>
                                                    <TableCell sx={{py: 2}}>
                                                        Aucun appareil connecté à cet utilisateur
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                this.state.user.devices
                                                    .sort((a, b) => a.lastUsed < b.lastUsed ? 1 : -1)
                                                    .map((device: any) => (
                                                    <TableRow
                                                        key={device.id}
                                                    >
                                                        <TableCell sx={{py: 2}}>
                                                            {device.name}
                                                        </TableCell>
                                                        <TableCell sx={{py: 0}}>
                                                            {new Date(
                                                                device.lastUsed * 1000
                                                            ).toLocaleDateString()}
                                                        </TableCell>
                                                        <TableCell sx={{py: 0}}>
                                                            <Box>
                                                                <List>
                                                                    <ListItem sx={{p: 0, my: 1}}>
                                                                        <Tooltip
                                                                            title="Déconnecter"
                                                                            placement="right"
                                                                        >
                                                                            <Button
                                                                                variant="outlined"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        action_user_id:
                                                                                        device.id,
                                                                                        delete: true,
                                                                                    })
                                                                                }}
                                                                                sx={{p: 0, px: 1}}
                                                                                color="error"
                                                                            >
                                                                                <Icon
                                                                                    path={mdiLogout}
                                                                                />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    )))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {this.state.delete ? (
                                    <Areyousure
                                        message="supprimer un appareil"
                                        action={async () => {
                                            // TODO : déplacer une méthode de classe et gérer les erreurs
                                            await UserApi.deleteUserDevice(this.state.action_user_id)
                                            window.location.reload();
                                        }}
                                        close={() => {
                                            this.setState({delete: false})
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    {this.state.zones?.length ? (
                        <Box sx={{pt: 2, pl: 10, minWidth: '25%'}}>
                            <Box sx={{pb: 2}}>
                                <h4>Zones autorisées</h4>
                            </Box>
                            <Box sx={{
                                p: 2, pt: 1,
                                backgroundColor: '#efffef',
                                borderRadius: 1
                            }}>
                                <Box>
                                    {this.state.zones.map((zone: any) => (
                                        <Box sx={{mt: 1}} key={zone.id}>{zone.name}</Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>) : (<></>)}
                </Box>

            </Box>
        )
    }
}