import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { GeoRestrictionApi } from '../../api/geo_restrictions_api'
import { GeoRestriction } from '../../interfaces/georestriction_interface'
import GeorestrictionRow from './georestriction_row'

export interface GeorestrictionsProps {}

interface Georestrictionstate {
    // TODO : renommer en list
    georestrictions?: GeoRestriction[]

    // TODO : supprimer et fusionner avec current_georestriction
    delete: boolean

    // TODO : supprimer ce champ inutile
    save: boolean

    // TODO : supprimer ce champ inutile
    newgeorestriction: boolean

    current_georestriction?: GeoRestriction

    // TODO : ce champ n'est jamais mis à jour
    cantPerform: boolean

    // TODO : supprimer ces champs inutiles
    canceled: number
    reloaded: number
}

export class Georestrictions extends React.Component<
    GeorestrictionsProps,
    Georestrictionstate
> {
    constructor(props: GeorestrictionsProps) {
        super(props)
        this.state = {
            delete: false,
            save: false,
            newgeorestriction: false,
            cantPerform: false,
            canceled: 0,
            reloaded: 0,
        }
        this.load = this.load.bind(this)
        this.askForDelete = this.askForDelete.bind(this)
        this.performDelete = this.performDelete.bind(this)
        this.performSave = this.performSave.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    // récupère la liste des géorestrictions
    async load() {
        try {
            const georestrictions =
                await GeoRestrictionApi.listGeoRestrictions()
            this.setState({
                georestrictions: GeneralFunctionsApi.SortByID(georestrictions),
            })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement de la liste des georestrictions')
        }
    }

    // TODO : supprimer cette méthode inutilisée
    // enregistre une géorestriction en base
    async performSave() {
        await GeoRestrictionApi.editGeorestriction(
            this.state.current_georestriction!
        )
        await this.load()
    }

    // Supprime une géorestiction en base
    async performDelete() {
        await GeoRestrictionApi.deleteGeorestriction(
            Number(this.state.current_georestriction?.id)
        )
        await this.load()
    }

    // Enregistre dans l'état les variables de la donnée (ici géorestriction) nécéssaires pour la suppression en base
    // Ouvre la modale de delete
    async askForDelete(georestriction: GeoRestriction) {
        this.setState({
            current_georestriction: georestriction,
        })
        this.setState({ delete: true })
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1, mb: 2 }}>
                    <h2>Gestion des géorestrictions</h2>
                </Box>
                <div>
                    <Link to="create">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                this.setState({
                                    newgeorestriction:
                                        !this.state.newgeorestriction,
                                })
                            }}
                        >
                            <span>Ajouter une georestriction</span>
                        </Button>
                    </Link>
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 1,
                    }}
                >
                    {this.state.georestrictions ? (
                        <TableContainer component={Paper} sx={{ my: 4 }}>
                            <Table aria-label="georestriction list table">
                                <TableHead
                                    sx={{
                                        backdropFilter: 2,
                                        backgroundColor: 'primary.main',
                                    }}
                                >
                                    <TableRow>
                                        <TableCell
                                            sx={{ color: '#ffffff' }}
                                            align="left"
                                        >
                                            #
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Nom
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Editer
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Supprimer
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.georestrictions?.map(
                                        (element: any) => (
                                            <GeorestrictionRow
                                                georestriction={element}
                                                key={element.id}
                                                askForDelete={this.askForDelete}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box
                            sx={{
                                height: 'calc(100vh - 200px)',
                                width: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {/* modale ne s'ouvrant que si la variable d'état delete passe à true, contient la fonction de suppression en base*/}
                    {this.state.delete ? (
                        <Areyousure
                            message={
                                <span>
                                    {' '}
                                    supprimer la georestriction{' '}
                                    <span>
                                        {
                                            this.state.current_georestriction
                                                ?.name
                                        }
                                    </span>
                                    .
                                    {this.state.cantPerform ? (
                                        <>
                                            <br />
                                            <br />

                                            <Typography color="secondary">
                                                Action impossible car des
                                                utilisateurs sont encore
                                                rattachés à la georestriction
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            }
                            close={() => {
                                this.setState({ delete: false })
                            }}
                            action={this.performDelete}
                            cantPerform={this.state.cantPerform}
                            reload={this.load}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        )
    }
}
