import { mdiDownload, mdiUpload } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import { CreditApi } from '../../api/credits_api'
import { Credit } from '../../interfaces/credit_interface'
import { CreditsTable } from './credits_table'

export interface CreditsProps {}

export interface CreditsState {
    cancel: boolean
    credits?: Credit[]
}

export class Credits extends React.Component<CreditsProps, CreditsState> {
    constructor(props: CreditsProps) {
        super(props)
        this.state = {
            cancel: false,
        }
        this.load = this.load.bind(this)
        this.orderCreditList = this.orderCreditList.bind(this)
        this.downloadCredits = this.downloadCredits.bind(this)
    }

    componentDidMount(): void {
        this.load()
        window.onbeforeunload = function () {
            return "Tout ce qui n'a pas été enregistré sera perdu. Êtes vous sûr de vouloir reloader cette page ?"
        }
    }

    async load() {
        try {
            const credits = await CreditApi.listCredits()
            this.setState({ credits: credits })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement des crédits')
        }
    }

    orderCreditList(newOrder: Credit[]) {
        this.setState({ credits: newOrder })
    }

    downloadCredits() {
        const blob = new Blob([JSON.stringify(this.state.credits!)], {
            type: 'application/json',
        })
        const fileUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = `Bioclimsol_Credits.json`
        a.href = fileUrl
        a.click()
        a.remove()
        URL.revokeObjectURL(fileUrl)
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1, mb: 2 }}>
                    <h2>Credits</h2>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Button
                        sx={{ mb: 2 }}
                        variant="outlined"
                        onClick={() => {
                            // TODO : déplacer dans une méthode de classe
                            const credits = this.state.credits
                            credits!.push({
                                icon: 'icon',
                                text: 'texte',
                                link: 'lien',
                            })
                            this.setState({ credits: credits })
                        }}
                    >
                        Ajouter un crédit
                    </Button>
                    {this.state.credits ? (
                        <Tooltip title="Télécharger">
                            <Button
                                sx={{ mb: 2, mx: 4 }}
                                variant="text"
                                onClick={this.downloadCredits}
                            >
                                <Icon path={mdiDownload} />
                            </Button>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    <Tooltip title="Téléverser">
                        <Button variant="text" component="label">
                            <Icon path={mdiUpload} className="app_svg" />
                            <input
                                type="file"
                                accept=".json"
                                hidden
                                onChange={async (event) => {
                                    // TODO : déplacer dans une méthode de la classe
                                    if (event.target.files) {
                                        try {
                                            this.downloadCredits()
                                            const credits = JSON.parse(
                                                await event.target.files[0].text()
                                            )
                                            CreditApi.replaceCreditList(
                                                credits
                                            ).then(() => {
                                                this.load()
                                            })
                                        } catch (e) {
                                            console.error(e)
                                            alert(
                                                'echec du remplacement des crédits'
                                            )
                                        }
                                    }
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Box>
                {this.state.credits ? (
                    <CreditsTable
                        credits={this.state.credits}
                        load={this.load}
                        orderCredits={this.orderCreditList}
                    />
                ) : (
                    <Box
                        sx={{
                            height: 'calc(100vh - 200px)',
                            width: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <Box sx={{ px: 40, display: 'flex' }}>
                    <Button
                        sx={{ mt: 5, mx: 1 }}
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            // TODO : déplacer dans une méthode de la classe
                            try {
                                // TODO : ajouter await ici
                                CreditApi.replaceCreditList(this.state.credits!)
                                alert('Les crédits ont été sauvegardés')
                                this.load()
                            } catch (e) {
                                console.error(e)
                                alert('Echec de la sauvegarde')
                            }
                        }}
                    >
                        Enregistrer
                    </Button>
                    <Button
                        sx={{ mt: 5, mx: 1 }}
                        variant="outlined"
                        color="inherit"
                        fullWidth
                        onClick={() => {
                            this.setState({ cancel: true })
                        }}
                    >
                        Annuler les modifications
                    </Button>
                </Box>
                {this.state.cancel ? (
                    <Areyousure
                        message="annuler les modifications en cours"
                        close={() => {
                            this.setState({ cancel: false })
                        }}
                        action={this.load}
                        reload={() => {}}
                    />
                ) : (
                    <></>
                )}
            </Box>
        )
    }
}
