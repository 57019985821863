import { Box, TextField } from '@mui/material'

// TODO : le nom "SearchInput" semble plus précis que ce que fais le widget : il n'y a aucune référence à une élément de recherche dans ce code
// TODO : add strong typing
export const SearchInput = ({
    changeValue,
    label,
    placeholder = '...',
    type,
}: any) => {
    return (
        <Box>
            <TextField
                type={type}
                id={label}
                name={label}
                placeholder={placeholder}
                onChange={(event) => {
                    changeValue(event.target.value)
                }}
                label={label}
                variant="standard"
                sx={{ marginRight: 2 }}
            />
        </Box>
    )
}
