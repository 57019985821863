import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CoordinatesPicker } from '../../components/CoordinatesPicker'
import { GeoRestrictionApi } from '../../api/geo_restrictions_api'
import { CoordinatesSquare } from '../../global_variables/tiles_config'
import { GeoRestriction } from '../../interfaces/georestriction_interface'

export function EditionGeorestrictionMiddleWare(): React.ReactElement {
    return <EditionGeorestriction params={useParams()} />
}

export interface EditGeorestrictionProps {
    params?: any
}

interface EditGeorestrictionState {
    // TODO : ce champ est inutilisé
    select_zone: boolean
    // TODO : renommer currRestriction
    current_georestriction: GeoRestriction
    // TODO : renommer en "ready"
    georestriction_is_loaded: boolean
}

export class EditionGeorestriction extends React.Component<
    EditGeorestrictionProps,
    EditGeorestrictionState
> {
    constructor(props: EditGeorestrictionProps) {
        super(props)
        this.state = {
            select_zone: false,
            current_georestriction: {
                name: '',
                minLon: 0,
                maxLon: 0,
                maxLat: 0,
                minLat: 0,
            },
            georestriction_is_loaded: false,
        }
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            // Dans le cas de la création d'une géorestriction, il n'y a pas de rechargement depuis le backend
            if (!this.props.params) {
                this.setState({
                    georestriction_is_loaded: true,
                })
                return
            }

            const params = this.props.params['*'].split('/')
            const id = params[2]
            await GeoRestrictionApi.getGeoRestriction(Number(id)).then(
                (response) => {
                    console.log(response)
                    this.setState({
                        current_georestriction: response,
                        georestriction_is_loaded: true,
                    })
                }
            )
        } catch (e) {
            alert('Ne peut pas récupérer la géorestriction')
            console.error(e)
        }
    }

    render() {
        return (
            <Box
                sx={{
                    p: 5,
                    whidth: 1,
                    height: 1,
                }}
            >
                <Box sx={{ borderBottom: 1 }}>
                    <h1>
                        {this.state.current_georestriction.id
                            ? 'Édition'
                            : 'Création'}{' '}
                        d'une géorestriction
                    </h1>
                </Box>
                <Box display="flex" sx={{ height: 0.7 }}>
                    <Grid container sx={{ width: 0.4 }}>
                        <FormControl sx={{ width: 1 }}>
                            <Grid container>
                                <Grid item lg={9}>
                                    <TextField
                                        required
                                        variant="standard"
                                        label="nom"
                                        sx={{ my: 5 }}
                                        value={
                                            this.state.current_georestriction
                                                .name
                                        }
                                        onChange={(event) => {
                                            // TODO : déplacer une méthode de classe
                                            const current_georestriction =
                                                this.state
                                                    .current_georestriction
                                            current_georestriction.name =
                                                event.target.value
                                            this.setState({
                                                current_georestriction:
                                                    current_georestriction,
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container gap={3}>
                                <Grid item lg={4.2}>
                                    <TextField
                                        variant="standard"
                                        label="Longitude min"
                                        sx={{ my: 5 }}
                                        type="number"
                                        value={
                                            this.state.current_georestriction
                                                .minLon
                                        }
                                        onChange={(event) => {
                                            // TODO : déplacer une méthode de classe
                                            let current_georestriction =
                                                this.state
                                                    .current_georestriction
                                            current_georestriction.minLon =
                                                Number(event.target.value)
                                            this.setState({
                                                current_georestriction:
                                                    current_georestriction,
                                            })
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={4.2}>
                                    <TextField
                                        variant="standard"
                                        label="Latitude min"
                                        sx={{ my: 5 }}
                                        type="number"
                                        value={
                                            this.state.current_georestriction
                                                ?.minLat
                                        }
                                        onChange={(event) => {
                                            // TODO : déplacer une méthode de classe
                                            let current_georestriction =
                                                this.state
                                                    .current_georestriction
                                            current_georestriction.minLat =
                                                Number(event.target.value)
                                            this.setState({
                                                current_georestriction:
                                                    current_georestriction,
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container gap={3}>
                                <Grid item lg={4.2}>
                                    <TextField
                                        variant="standard"
                                        label="Longitude max"
                                        sx={{ my: 5 }}
                                        type="number"
                                        value={
                                            this.state.current_georestriction
                                                ?.maxLon
                                        }
                                        onChange={(event) => {
                                            // TODO : déplacer une méthode de classe
                                            let current_georestriction =
                                                this.state
                                                    .current_georestriction
                                            current_georestriction.maxLon =
                                                Number(event.target.value)
                                            this.setState({
                                                current_georestriction:
                                                    current_georestriction,
                                            })
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={4.2}>
                                    <TextField
                                        variant="standard"
                                        label="Latitude max"
                                        sx={{ my: 5 }}
                                        type="number"
                                        value={
                                            this.state.current_georestriction
                                                ?.maxLat
                                        }
                                        onChange={(event) => {
                                            // TODO : déplacer une méthode de classe
                                            let current_georestriction =
                                                this.state
                                                    .current_georestriction
                                            current_georestriction.maxLat =
                                                Number(event.target.value)
                                            this.setState({
                                                current_georestriction:
                                                    current_georestriction,
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item lg={9}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ my: 4 }}
                                    onClick={async () => {
                                        // TODO : déplacer une méthode de classe
                                        try {
                                            if (
                                                this.state
                                                    .current_georestriction
                                                    .name === ''
                                            ) {
                                                alert(
                                                    'La géorestriction doit être nommée'
                                                )
                                                return
                                            }

                                            console.log(
                                                this.state
                                                    .current_georestriction
                                            )
                                            if (
                                                this.state
                                                    .current_georestriction
                                                    .id !== undefined
                                            ) {
                                                await GeoRestrictionApi.editGeorestriction(
                                                    this.state
                                                        .current_georestriction
                                                ).then((response) => {
                                                    console.log(response)
                                                    window.location.href =
                                                        '/georestrictions'
                                                })
                                            } else {
                                                await GeoRestrictionApi.saveGeorestriction(
                                                    this.state
                                                        .current_georestriction
                                                ).then((response) => {
                                                    console.log(response)
                                                    window.location.href =
                                                        '/georestrictions'
                                                })
                                            }
                                        } catch (e) {
                                            console.error(e)
                                            alert(
                                                'Problème à la sauvegarde de la géorestriction'
                                            )
                                        }
                                    }}
                                >
                                    Valider
                                </Button>
                            </Grid>
                        </FormControl>
                    </Grid>

                    {this.state.georestriction_is_loaded ? (
                        <Box
                            sx={{
                                width: 1,
                                m: 5,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CoordinatesPicker
                                set_new_location={(s: CoordinatesSquare) => {
                                    // TODO : déplacer une méthode de classe
                                    const current_georestriction = {
                                        id: this.state.current_georestriction
                                            .id,
                                        name: this.state.current_georestriction
                                            .name,
                                        minLon: s.min.lon,
                                        maxLon: s.max.lon,
                                        maxLat: s.min.lat, // QUESTION : TYPO ? ou normal ?
                                        minLat: s.max.lat,
                                    }

                                    this.setState({
                                        current_georestriction:
                                            current_georestriction,
                                    })
                                }}
                                current_location={{
                                    min: {
                                        lon: this.state.current_georestriction
                                            .minLon,
                                        lat: this.state.current_georestriction
                                            .minLat,
                                    },
                                    max: {
                                        lon: this.state.current_georestriction
                                            .maxLon,
                                        lat: this.state.current_georestriction
                                            .maxLat,
                                    },
                                }}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                width: 1,
                                m: 5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }
}
