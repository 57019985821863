import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '../../routes/authentification/login_route'
import { OpenIdCallback } from '../../routes/authentification/openId_callback_route'
import { DashboardMiddleWare } from '../Dashboard/Dashboard'

/**
 * Routeur principal de l'application
 */
export function Router(): React.ReactElement {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<DashboardMiddleWare />} />
                <Route path="/login" element={<Login />} />
                <Route path="/openid_callback" element={<OpenIdCallback />} />
            </Routes>
        </BrowserRouter>
    )
}
