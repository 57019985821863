import React from "react";
import {useParams} from "react-router-dom";
import {
    Project
} from "../../interfaces/project_interface";
import {ProjectApi} from "../../api/projects_api";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ic_general_informations from "../../assets/images/ic_general_informations.svg";
import ic_deperis from "../../assets/images/ic_deperis.svg";
import ic_leaf2 from "../../assets/images/ic_leaf2.svg";
import ic_pedology from "../../assets/images/ic_pedology.svg";
import ic_population_charactertics from "../../assets/images/ic_population_characteristics.svg";
import ic_stationary_context from "../../assets/images/ic_stationary_context.svg";

export function ViewProjectMiddleWare(): React.ReactElement {
    return <ViewProject params={useParams()}/>
}
console.log(ic_deperis)
export interface ViewProjectProps {
    params?: any
}

interface ViewProjectState {
    project: Project
}

export class ViewProject extends React.Component<ViewProjectProps, ViewProjectState>{
    constructor(props: ViewProjectProps) {
        super(props)
        this.state = {
            project: {
                id: -1,
                userId: -1,
                data: {
                    name: '',
                    lat: 0,
                    lon: 0,
                    commune: '',
                    comment: '',
                    date: '',
                    creationDate: '',
                    operator: '',
                    forestName: '',
                    pedology: {
                        causeArretTariere: '',
                        comment: '',
                        compactness: '',
                        groundType: '',
                        horizons: [],
                        humus: '',
                        ph20: 0,
                        phMeasured: false,
                        rockType: '',
                        ucs: 0,
                        usefulReserve: 0,
                        usefulReserve200: 0
                    },
                    dieback: {
                        comment: '',
                        environmentFrequency: '',
                        environmentIntensity: '',
                        hasMandatoryComplete: '',
                        parcelFrequency: '',
                        parcelIntensity: '',
                        tree: []
                    },
                    populationCharacteristics: {
                        woodSpecies: '',
                        populationType: '',
                        recentInterventions: '',
                        averageDiameter: '',
                        averageDiameterMeasured: false,
                        dominantHeight: 0,
                        dominantHeightMeasured: false,
                        basalArea: 0,
                        basalAreaMesured: false,
                        age: 0,
                        ageMeasured: 0,
                        comment: ''
                    },
                    sanitaryIssues: {
                        guiSeverity: '',
                        guiAffectedStems: '',
                        defoliateurSeverity: '',
                        defoliateurAffectedStems: '',
                        scolyteSeverity: '',
                        scolyteAffectedStems: '',
                        cynipsSeverity: '',
                        cynipsAffectedStems: '',
                        otherInsectsSeverity: '',
                        otherInsectsAffectedStems: '',
                        chancreSeverity: '',
                        chancreAffectedStems: '',
                        encreSeverity: '',
                        encreAffectedStems: '',
                        oidiumSeverity: '',
                        oidiumAffectedStems: '',
                        otherFungiSeverity: '',
                        otherFungiAffectedStems: '',
                        decolorationSeverity: '',
                        decolorationAffectedStems: '',
                        necrosesSeverity: '',
                        necrosesAffectedStems: '',
                        comment: ''
                    },
                    stationaryContext: {
                        altitude: 0,
                        exposureUnit: '',
                        exposureValue: 0,
                        slopeUnit:'',
                        slopeValue: 0,
                        containmentUnit: '',
                        containmentValue: 0,
                        topographicPosition: '',
                        tpi: 0,
                        watterSupply: '',
                        rockyOutcrops: 0,
                        terracements: false,
                        comment: ''
                    }
                },
                timeCreate: 0,
                timeUpdate: 0,
                archive: false,
                shareWithCnpf: false,
                isClosed: false
            },
        }
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            if(this.props.params){
                const params = this.props.params['*'].split('/')
                const id = params[1]
                const project = await ProjectApi.getProject(id);
                this.setState({
                    project: {
                        id: project.id,
                        userId: project.userId,
                        data: project.data,
                        timeCreate: project.timeCreate,
                        timeUpdate: project.timeUpdate,
                        archive: project.archive,
                        shareWithCnpf: project.shareWithCnpf,
                        isClosed: project.isClosed
                    },
                })
            }
        } catch (e) {
            console.error(e)
            alert("Echec du chargement des projets")
        }
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <Box sx={{borderBottom: 1, mb: 2}}>
                    <h2>Détails du projet {this.state.project.data.name}</h2>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{pt: 2, minWidth: '30%'}}>
                        <Box sx={{pb: 2}}>
                            <h4>Informations générales</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_general_informations})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Nom:</span> <span>{this.state.project.data.name}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Forêt:</span> <span>{this.state.project.data.forestName}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Commune:</span> <span>{this.state.project.data.commune}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                <span>Longitude:</span> <span>{this.state.project.data.lon}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Latitude:</span> <span>{this.state.project.data.lat}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                <span>Opérateur:</span> <span>{this.state.project.data.operator}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Date de création:</span> <span>{new Date(
                                this.state.project.timeCreate * 1000
                            ).toLocaleDateString()}</span>
                            </Box>
                            {this.state.project.timeUpdate ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Date de mise à jour:</span> <span>{new Date(
                                    this.state.project.timeUpdate* 1000
                                ).toLocaleDateString()}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.shareWithCnpf ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Projet partagé avec le CNPF:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Projet partagé avec le CNPF:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.project.isClosed ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>État du projet:</span> <span>FERMÉ</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>État du projet:</span> <span>OUVERT</span>
                                </Box>
                            )}
                            {this.state.project.archive ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span></span> <span>ARCHIVÉ</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span></span> <span>NON ARCHIVÉ</span>
                                </Box>
                            )}
                            {this.state.project.data.comment !== "" ? (
                                <Box sx={{ maxWidth:'40vh'}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Commentaire:</span>
                                </Box>
                                <Box sx={{display: 'flex', mt: 1}}>
                                    <span>{this.state.project.data.comment}</span>
                                </Box>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                        <Box sx={{pb: 2, pt:2}}>
                            <h4>Caractéristiques du peuplement</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_population_charactertics})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            {this.state.project.data.populationCharacteristics.woodSpecies !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Essence:</span> <span>{this.state.project.data.populationCharacteristics.woodSpecies}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.populationType !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Type de peuplement:</span> <span>{this.state.project.data.populationCharacteristics.populationType}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.recentInterventions !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Intervention récente:</span> <span>{this.state.project.data.populationCharacteristics.recentInterventions}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.averageDiameter !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                    <span>Diamètre moyen:</span> <span>{this.state.project.data.populationCharacteristics.averageDiameter}cm</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.averageDiameterMeasured ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Diamètre moyen mesurée:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Diamètre moyen mesurée:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.project.data.populationCharacteristics.dominantHeight !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Hauteur dominante:</span> <span>{this.state.project.data.populationCharacteristics.dominantHeight}m</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.dominantHeightMeasured ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Hauteur dominante mesurée:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Hauteur dominante mesurée:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.project.data.populationCharacteristics.basalArea !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Surface Terrière:</span> <span>{this.state.project.data.populationCharacteristics.basalArea}m2/ha</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.basalAreaMesured ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Surface Terrière mesurée:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Surface Terrière mesurée:</span> <span>NON</span>
                                </Box>
                            )}
                            {this.state.project.data.populationCharacteristics.age !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Age:</span> <span>{this.state.project.data.populationCharacteristics.age}ans</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.populationCharacteristics.ageMeasured ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Age connu:</span> <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Age connu:</span> <span>NON</span>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{pt: 2, pl: 6, minWidth: '30%'}}>
                        <Box sx={{pb: 2}}>
                            <h4>Pédologie</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_pedology})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            {this.state.project.data.pedology.causeArretTariere !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Cause arrêt tarière:</span>
                                    <span>{this.state.project.data.pedology.causeArretTariere}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.compactness !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Compacité:</span>
                                    <span>{this.state.project.data.pedology.compactness}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.humus !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Humus:</span>
                                    <span>{this.state.project.data.pedology.humus}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.ph20 !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>pH20:</span>
                                    <span>{this.state.project.data.pedology.ph20}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.phMeasured  ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>pH mesuré:</span>
                                    <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>pH mesuré:</span>
                                    <span>NON</span>
                                </Box>
                            )}
                            {((this.state.project.data.pedology.comment !== "") && (this.state.project.data.pedology.comment !== null)) ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Commentaire:</span>
                                    <span>{this.state.project.data.pedology.comment}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.pedology.horizons.length ? (
                                <Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                        <span>Horizons:</span>
                                    </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <TableContainer component={Paper} sx={{marginTop: 1}}>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    backdropFilter: 2,
                                                    backgroundColor: 'primary.main',
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        #
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Épaisseur
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Texture
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        EG
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Efferv.
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.project.data.pedology.horizons
                                                        .map((horizons: any) => (
                                                            <TableRow
                                                                key={horizons.num}
                                                            >
                                                                <TableCell sx={{py: 2}}>
                                                                    {horizons.num}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {horizons.thickness}cm
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {horizons.texture}
                                                                </TableCell>
                                                                <TableCell sx={{py: 0}}>
                                                                    {horizons.eg}%
                                                                </TableCell>
                                                                {horizons.effervescence ? (
                                                                    <TableCell sx={{py: 0}}>
                                                                        OUI
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell sx={{py: 0}}>
                                                                        NON
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                        <Box sx={{pb: 2,pt:2}}>
                            <h4>Contexte stationnel</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_stationary_context})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Altitude:</span> <span>{this.state.project.data.stationaryContext.altitude}m</span>
                            </Box>
                            {this.state.project.data.stationaryContext.exposureUnit !== 'none' ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Exposition:</span>
                                    <span>{this.state.project.data.stationaryContext.exposureValue}{this.state.project.data.stationaryContext.exposureUnit}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.slopeValue!== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Pente:</span>
                                    <span>{this.state.project.data.stationaryContext.slopeValue}{this.state.project.data.stationaryContext.slopeUnit}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.containmentValue!== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Confinement:</span>
                                    <span>{this.state.project.data.stationaryContext.containmentValue}{this.state.project.data.stationaryContext.containmentValue}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.topographicPosition !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Position topographique:</span>
                                    <span>{this.state.project.data.stationaryContext.topographicPosition}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.watterSupply !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Alimentation en eau:</span>
                                    <span>{this.state.project.data.stationaryContext.watterSupply}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.rockyOutcrops !== null ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Affleurements rocheux:</span>
                                    <span>{this.state.project.data.stationaryContext.rockyOutcrops}%</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.stationaryContext.terracements ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Présence de terrasse:</span>
                                    <span>OUI</span>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Présence de terrasse:</span>
                                    <span>NON</span>
                                </Box>
                            )}
                            {((this.state.project.data.stationaryContext.comment !== "") && (this.state.project.data.stationaryContext.comment !== null)) ? (
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <span>Commentaire:</span>
                                    <span>{this.state.project.data.stationaryContext.comment}</span>
                                </Box>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{pt: 2, pl: 6, minWidth: '30%'}}>
                        <Box sx={{pb: 2}}>
                            <h4>Dépérissement</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_deperis})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Proportion d'arbres dépérissants:</span> <span>{this.state.project.data.dieback.parcelFrequency}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Présence de résilience:</span> <span>{this.state.project.data.dieback.parcelIntensity}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Problème majeur récent (5 ans):</span> <span>{this.state.project.data.dieback.environmentFrequency}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Sévérité du problème:</span> <span>{this.state.project.data.dieback.environmentIntensity}</span>
                            </Box>
                            {((this.state.project.data.dieback.comment !== "") && (this.state.project.data.dieback.comment !== null))? (
                                <Box sx={{ maxWidth:'40vh'}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                                        <span>Commentaire:</span>
                                    </Box>
                                    <Box sx={{display: 'flex', mt: 1}}>
                                        <span>{this.state.project.data.dieback.comment}</span>
                                    </Box>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {this.state.project.data.dieback.tree?.length ? (
                                <Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                                        <span>Notation des arbres:</span>
                                    </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                    <TableContainer component={Paper} sx={{marginTop: 1}}>
                                        <Table>
                                            <TableHead
                                                sx={{
                                                    backdropFilter: 2,
                                                    backgroundColor: 'primary.main',
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        #
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Ramif.
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Mort.
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Archi
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Deper.
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Ess.
                                                    </TableCell>
                                                    <TableCell sx={{color: '#ffffff'}}>
                                                        Diam.
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.project.data.dieback.tree
                                                    .map((arbres: any) => (
                                                        <TableRow
                                                            key={arbres.treenumber}
                                                        >
                                                            <TableCell sx={{py: 2}}>
                                                                {arbres.treenumber}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.protocolDEPRISLackRamifications}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.protocolDEPERISBranchMortalities}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.protocolARCHI}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.protocolDEPERISNote}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.essence}
                                                            </TableCell>
                                                            <TableCell sx={{py: 0}}>
                                                                {arbres.diameter}
                                                            </TableCell>
                                                        </TableRow>))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                </Box>
                                ) : (
                                    <></>
                                )}
                        </Box>
                        <Box sx={{pb: 2,pt:2}}>
                            <h4>Problèmes sanitaires</h4>
                        </Box>
                        <Box sx={{
                            p: 2, pt: 1,
                            position: 'relative',
                            borderRadius: 1,
                            backgroundColor: '#efffef',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage: `url(${ic_leaf2})`,
                                backgroundSize: '40%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.05,
                                zIndex: 1,
                                borderRadius: 1,
                            },
                        }}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>GUI:</span> <span>{this.state.project.data.sanitaryIssues.guiSeverity} atteinte à {this.state.project.data.sanitaryIssues.guiAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Défoliateur:</span> <span>{this.state.project.data.sanitaryIssues.defoliateurSeverity} atteinte à {this.state.project.data.sanitaryIssues.defoliateurAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Scolyte(s):</span> <span>{this.state.project.data.sanitaryIssues.scolyteSeverity} atteinte à {this.state.project.data.sanitaryIssues.scolyteAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Cynips:</span> <span>{this.state.project.data.sanitaryIssues.cynipsSeverity} atteinte à {this.state.project.data.sanitaryIssues.cynipsAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Autres insectes:</span> <span>{this.state.project.data.sanitaryIssues.otherInsectsSeverity} atteinte à {this.state.project.data.sanitaryIssues.otherInsectsAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Chancre:</span> <span>{this.state.project.data.sanitaryIssues.chancreSeverity} atteinte à {this.state.project.data.sanitaryIssues.chancreAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Encre:</span> <span>{this.state.project.data.sanitaryIssues.encreSeverity} atteinte à {this.state.project.data.sanitaryIssues.encreAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Oïdium:</span> <span>{this.state.project.data.sanitaryIssues.oidiumSeverity} atteinte à {this.state.project.data.sanitaryIssues.oidiumAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Autres champignons:</span> <span>{this.state.project.data.sanitaryIssues.otherFungiSeverity} atteinte à {this.state.project.data.sanitaryIssues.otherFungiAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Décoloration:</span> <span>{this.state.project.data.sanitaryIssues.decolorationSeverity} atteinte à {this.state.project.data.sanitaryIssues.decolorationAffectedStems}</span>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 1}}>
                                <span>Nécroses:</span> <span>{this.state.project.data.sanitaryIssues.necrosesSeverity} atteinte à {this.state.project.data.sanitaryIssues.necrosesAffectedStems}</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}