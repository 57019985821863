import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { Project } from '../../interfaces/project_interface'
import { ProjectRow } from './project_row'
import React from 'react'
import { ProjectApi } from '../../api/projects_api'

export interface ProjectsList {
    projects: Project[]
}

export class AllProjects extends React.Component<ProjectsList> {
    async downloadAll() {
        console.log("Téléchargement ensemble des projets de l'utilisateur")

        try {
            const download = await ProjectApi.AllProjectsCsv()
            const blobUrl = URL.createObjectURL(download)
            const link = document.createElement('a')

            link.href = blobUrl
            link.download = `all-projects.tar.gz`
            link.click()
            URL.revokeObjectURL(blobUrl)
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return (
            <Box>
                <h2>Liste des projets de BioClimSol</h2>
                <Button variant="outlined" onClick={this.downloadAll}>
                    Télécharger tout les projets en Base de données
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>User Id</TableCell>
                                <TableCell>Télécharger</TableCell>
                                <TableCell>Télécharger</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.projects.map((project) => (
                                <ProjectRow project={project} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}
