import { mdiArrowDownBold, mdiArrowUpBold, mdiDelete, mdiUpload } from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { EditCell } from '../../components/EditCell/EditCell'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import { CreditApi } from '../../api/credits_api'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { Credit } from '../../interfaces/credit_interface'

export interface CreditsTableProps {
    credits: Credit[]
    // TODO renommer cette fonction
    load: () => void
    orderCredits: (newOrder: Credit[]) => void
}

export interface CreditsTableState {
    // TODO : supprimer ce champs inutile
    delete: boolean
    current_item_id: string
}

export class CreditsTable extends React.Component<
    CreditsTableProps,
    CreditsTableState
> {
    constructor(props: CreditsTableProps) {
        super(props)
        this.state = {
            delete: false,
            current_item_id: '',
        }
        this.editLink = this.editLink.bind(this)
        this.editText = this.editText.bind(this)
    }

    async handleNewIcon(event: any, index: number) {
        const base64_img = await GeneralFunctionsApi.convertToBase64(
            event.target.files[0]
        )
        const credits = this.props.credits
        credits[index].icon = base64_img
        this.props.orderCredits(credits)
    }

    async handleDelete(index: number) {
        const credits = this.props.credits
        credits.splice(index, 1)
        this.props.orderCredits(credits)
    }

    editLink(index: number, link: string) {
        const credits = this.props.credits
        credits[index].link = link
        this.props.orderCredits(credits)
    }

    editText(index: number, text: string) {
        const credits = this.props.credits
        credits[index].text = text
        this.props.orderCredits(credits)
    }

    render() {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Icône
                                </TableCell>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Texte
                                </TableCell>
                                <TableCell sx={{ color: '#ffffff' }}>
                                    Lien
                                </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.credits.map(
                                (credit: Credit, index: number) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#0084730c',
                                            },
                                        }}
                                    >
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <img
                                                    src={credit.icon}
                                                    alt="icon"
                                                    style={{
                                                        maxHeight: 80,
                                                        maxWidth: 80,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Tooltip
                                                        title="Téléverser"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            variant="text"
                                                            color="inherit"
                                                            component="label"
                                                            sx={{ mx: 2 }}
                                                        >
                                                            <Icon
                                                                path={mdiUpload}
                                                                className="app_svg"
                                                            />
                                                            <input
                                                                type="file"
                                                                accept=".jpeg, .png, .jpg"
                                                                hidden
                                                                id={credit.id?.toString()}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.handleNewIcon(
                                                                        event,
                                                                        index
                                                                    )
                                                                }}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <EditCell
                                                text={credit.text}
                                                changeValue={this.editText}
                                                index={index}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <EditCell
                                                text={credit.link}
                                                changeValue={this.editLink}
                                                index={index}
                                            />
                                        </TableCell>

                                        <TableCell sx={{ py: 0 }}>
                                            <List>
                                                <ListItem sx={{ p: 0, my: 1 }}>
                                                    {index !== 0 ? (
                                                        <Tooltip
                                                            title="Monter"
                                                            placement="right"
                                                        >
                                                            <Button
                                                                sx={{ p: 0 }}
                                                                variant="outlined"
                                                                color="info"
                                                                onClick={() => {
                                                                    // TODO : déplacer dans une fonction de classe
                                                                    const credits =
                                                                        GeneralFunctionsApi.SwitchEntries(
                                                                            index,
                                                                            index -
                                                                                1,
                                                                            this
                                                                                .props
                                                                                .credits
                                                                        )
                                                                    this.props.orderCredits(
                                                                        credits
                                                                    )
                                                                }}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiArrowUpBold
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </ListItem>
                                                <ListItem sx={{ p: 0, my: 1 }}>
                                                    <Tooltip
                                                        title="Détruire"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            sx={{ p: 0 }}
                                                            id={credit.id?.toString()}
                                                            onClick={() => {
                                                                this.handleDelete(
                                                                    index
                                                                )
                                                            }}
                                                        >
                                                            <Icon
                                                                path={mdiDelete}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </ListItem>
                                                <ListItem sx={{ p: 0, my: 1 }}>
                                                    {index !==
                                                    this.props.credits.length -
                                                        1 ? (
                                                        <Tooltip
                                                            title="Descendre"
                                                            placement="right"
                                                        >
                                                            <Button
                                                                sx={{ p: 0 }}
                                                                variant="outlined"
                                                                color="info"
                                                                onClick={() => {
                                                                    // TODO : déplacer dans une méthode de classe
                                                                    const credits =
                                                                        GeneralFunctionsApi.SwitchEntries(
                                                                            index,
                                                                            index +
                                                                                1,
                                                                            this
                                                                                .props
                                                                                .credits
                                                                        )
                                                                    this.props.orderCredits(
                                                                        credits
                                                                    )
                                                                }}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiArrowDownBold
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </ListItem>
                                            </List>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.delete ? (
                    // Ce code ne semble pas utilisé avec l'interface?
                    <Areyousure
                        message="supprimer un crédit"
                        action={() => {
                            // TODO : supprimer localement, appliquer la suppression sur l'API avec la mise à jour (appui du bouton enregistrer)
                            CreditApi.deleteCredit(
                                this.state.current_item_id
                            ).then(() => {
                                this.props.load()
                            })
                        }}
                        close={() => {
                            this.setState({ delete: false })
                        }}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}
