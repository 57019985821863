import { mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import {
    Box,
    Button,
    TextareaAutosize,
    TextField,
    Tooltip,
} from '@mui/material'
import { useState } from 'react'

export function EditCell(props: {
    text: string
    // TODO : renommer onValueChanged
    changeValue: (index: number, text: string) => void
    index: number
}) {
    const [isEditing, setEditing] = useState(false)

    function toggleEditing() {
        setEditing(!isEditing)
    }
    return (
        <Box sx={{ py: 0 }}>
            {isEditing ? (
                <>
                    <TextField
                        value={props.text}
                        sx={{ p: 1 }}
                        variant="standard"
                        onChange={(event) => {
                            props.changeValue(props.index, event.target.value)
                        }}
                    />
                    <Button color="inherit" onClick={toggleEditing}>
                        Ok
                    </Button>
                </>
            ) : (
                <Tooltip title="Cliquer pour éditer" placement="left">
                    <Box onClick={toggleEditing} display="flex">
                        {props.text}
                        <Icon
                            path={mdiPencil}
                            className="app_svg"
                            style={{ marginLeft: 20, width: 15 }}
                        />
                    </Box>
                </Tooltip>
            )}
        </Box>
    )
}

export function EditCellArea(props: {
    text: string
    changeValue: (index: number, text: string) => void
    index: number
}) {
    const [isEditing, setEditing] = useState(false)

    function toggleEditing() {
        setEditing(!isEditing)
    }
    return (
        <Box sx={{ py: 0 }}>
            {isEditing ? (
                <>
                    <TextareaAutosize
                        value={props.text}
                        onChange={(event) => {
                            props.changeValue(props.index, event.target.value)
                        }}
                    />
                    <Button color="inherit" onClick={toggleEditing}>
                        Ok
                    </Button>
                </>
            ) : (
                <Tooltip title="Cliquer pour éditer" placement="left">
                    <Box onClick={toggleEditing} display="flex">
                        <Box>
                            {props.text.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </Box>
                        <Icon
                            path={mdiPencil}
                            className="app_svg"
                            style={{ marginLeft: 20, width: 15 }}
                        />
                    </Box>
                </Tooltip>
            )}
        </Box>
    )
}
