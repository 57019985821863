import {mdiDelete, mdiLockReset, mdiPencil} from '@mdi/js'
import Icon from '@mdi/react'
import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import {Box} from '@mui/system'
import React from 'react'
import {Link} from 'react-router-dom'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import {RenewLicence} from '../../components/Popup/RenewLicence/RenewLicence'
import {UserApi} from '../../api/users_api'
import {User} from '../../interfaces/user_interface'

export interface UserTableProps {
    users: User[]
    reload: any
}

interface TableState {
    all_checked: boolean
    checked_users: Set<number>
    delete: boolean
    change_password: boolean // TODO : remplacer delete & chnage_password par un enum d'action initialisé à null
    action_user_id: number
    new_password: string // TODO : supprimer
    renew_licence: boolean
    new_date: number
}

export class UserTable extends React.Component<UserTableProps, TableState> {
    constructor(props: any) {
        super(props)
        this.state = {
            all_checked: false, // TODO : supprimer et remplacer par un simple getter
            checked_users: new Set(),
            delete: false,
            change_password: false,
            action_user_id: NaN,
            new_password: '',
            renew_licence: false,
            new_date: NaN
        }

        this.checkUser = this.checkUser.bind(this)
        this.uncheckUser = this.uncheckUser.bind(this)
        this.groupedLicenceRenewal = this.groupedLicenceRenewal.bind(this)
    }

    async toggleAllCheck() {
        this.setState({all_checked: !this.state.all_checked})

        await this.checkAllUser()
    }

    async checkAllUser() {
        if (!this.state.all_checked) {
            this.props.users.forEach(async (user: User) => await this.checkUser(user.id!))
        } else {
            this.props.users.forEach(async (user: User) => await this.uncheckUser(user.id!))
        }
    }

    async toggleThisCheck(user_id: number) {
        if (this.getUserCheckedStatus(user_id)) {
            await this.uncheckUser(user_id)
        } else {
            await this.checkUser(user_id)
        }
    }

    async checkUser(user_id: number) {
        this.setState(({checked_users}) => ({
            checked_users: new Set(checked_users).add(user_id),
        }))
    }

    async uncheckUser(user_id: number) {
        this.setState(({checked_users}) => {
            const new_checked = new Set(checked_users)
            new_checked.delete(user_id)

            return {
                checked_users: new_checked,
            }
        })

    }

    getUserCheckedStatus(user_id: number) {
        return this.state.checked_users.has(user_id)
    }

    async forEachLicenceRenewal(newDate: number) {
        this.state.checked_users.forEach(id => {
            this.updateLicenceRenew(id, newDate)
        })

        //window.location.reload()
    }

    async updateLicenceRenew(user_id: number, newDate: number) {
        let user = await UserApi.getUser(user_id)
        user.expireOn = newDate
        UserApi.updateLicenceRenew(user).then()

    }

    async groupedLicenceRenewal(newDate: number) {
         await this.forEachLicenceRenewal(newDate)

        await this.props.reload()
        window.location.reload()
    }

    getNumberOfDaysLastUsed(user: any) {
        if (user.devices[0] !== undefined) {
            let numberOfDays = Math.floor((new Date().getTime() - new Date((user.devices[0]).lastUsed * 1000).getTime()) / (24 * 1000 * 60 * 60))
            if (numberOfDays > 99 && numberOfDays <= 190) {
                return "+ de 3 mois"
            }
            if (numberOfDays > 190) {
                return "+ de 6 mois"
            }
            return numberOfDays + " jours"
        }
        return "Jamais"
    }

    // TODO : mettre en place une forme de pagination
    render() {
        return (
            <>
                <FormControl
                    sx={{
                        width: 200,
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        m: 5,
                        backdropFilter: 'blur(8px)',
                        zIndex: 10,
                        backgroundColor: '#ffffff1c',
                        color: 'text.primary',
                    }}
                >
                    <InputLabel>Action Groupée</InputLabel>
                    <Select>
                        <MenuItem
                            value={2}
                            onClick={() => {
                                this.setState({
                                    renew_licence: !this.state.renew_licence,
                                })
                            }
                            }
                        >
                            Renouveler les Licences
                        </MenuItem>
                    </Select>
                </FormControl>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead
                            sx={{
                                backdropFilter: 2,
                                backgroundColor: 'primary.main',
                            }}
                        >
                            <TableRow>
                                <TableCell align="left">
                                    <Checkbox
                                        sx={{
                                            color: '#ffffff',
                                            '&.Mui-checked': {
                                                color: '#ffffff',
                                            },
                                        }}
                                        onChange={() => {
                                            this.toggleAllCheck().then()
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    #
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Prénom
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Nom
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Email
                                </TableCell>
                                <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                    Structure
                                </TableCell>
                                <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                    <Box>
                                        <span>Dernière</span>
                                        <br/>
                                        <span>Connexion</span>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Expire le
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    CNPF
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Actif
                                </TableCell>
                                <TableCell sx={{color: '#ffffff'}}>
                                    Admin
                                </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.users.map((user: any) => (
                                <TableRow
                                    key={user.id}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#0084730c',
                                        },
                                    }}
                                >
                                    <TableCell
                                        key={user.id}
                                        sx={{px: 4, py: 0}}
                                    >
                                        <input
                                            type="checkbox"
                                            id={user.id}
                                            onChange={() => {
                                                this.toggleThisCheck(user.id).then()
                                            }}
                                            checked={this.getUserCheckedStatus(
                                                user.id
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {user.id}
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {user.firstname}
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {user.lastname}
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {user.email}
                                    </TableCell>
                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                        {user.structure_name ?? "Aucune"}
                                    </TableCell>
                                    <TableCell sx={{py: 0, textAlign: 'center'}}>
                                        {this.getNumberOfDaysLastUsed(user)}
                                    </TableCell>
                                    <TableCell sx={{py: 0}}>
                                        {new Date(
                                            user.expireOn * 1000
                                        ).toLocaleDateString()}
                                    </TableCell>
                                    <UserBooleanCell
                                        bool={user.isCnpf}
                                        toggle_active={false}
                                        user_id={user.id}
                                        reload={this.props.reload}
                                    />
                                    <UserBooleanCell
                                        bool={user.isActive}
                                        toggle_active={true}
                                        user_id={user.id}
                                        reload={this.props.reload}
                                    />
                                    <UserBooleanCell
                                        bool={user.isAdmin}
                                        toggle_active={false}
                                        user_id={user.id}
                                        reload={this.props.reload}
                                    />
                                    <TableCell sx={{py: 0}}>
                                        <Box>
                                            <List>
                                                <ListItem sx={{p: 0, my: 1}}>
                                                    <Link
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        to={`edit/${user.id}`}
                                                    >
                                                        <Tooltip
                                                            title="Éditer"
                                                            placement="right"
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                sx={{p: 0}}
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiPencil
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </Link>
                                                </ListItem>
                                                <ListItem sx={{p: 0, my: 1}}>
                                                    <Tooltip
                                                        title="Supprimer"
                                                        placement="right"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => {
                                                                this.setState({
                                                                    action_user_id:
                                                                    user.id,
                                                                    delete: true,
                                                                })
                                                            }}
                                                            sx={{p: 0, px: 1}}
                                                            color="error"
                                                        >
                                                            <Icon
                                                                path={mdiDelete}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </ListItem>
                                                {!user.isCnpf ? (
                                                    <ListItem
                                                        sx={{p: 0, my: 1}}
                                                    >
                                                        <Tooltip
                                                            title="Modifier le mot de passe"
                                                            placement="right"
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            action_user_id:
                                                                            user.id,
                                                                            change_password:
                                                                                true,
                                                                        }
                                                                    )
                                                                }}
                                                                sx={{
                                                                    p: 0,
                                                                    px: 1,
                                                                }}
                                                                color="info"
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiLockReset
                                                                    }
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ListItem>
                                                ) : (
                                                    <></>
                                                )}
                                            </List>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.delete ? (
                    <Areyousure
                        message="supprimer un utilisateur"
                        action={async () => {
                            // TODO : déplacer une méthode de classe et gérer les erreurs
                            await UserApi.deleteUser(this.state.action_user_id)
                        }}
                        reload={async () => {
                            await this.props.reload()
                        }}
                        close={() => {
                            this.setState({delete: false})
                        }}
                    />
                ) : (
                    <></>
                )}
                {this.state.change_password ? (
                    <Areyousure
                        message="modifier le mot de passe de l'utilisateur"
                        action={async () => {
                            // TODO : créer une méthode de classe
                            const response = await UserApi.resetPassword(
                                this.state.action_user_id
                            )
                            console.log(response)
                            this.setState({new_password: response.password})
                        }}
                        reload={async () => {
                            await this.props.reload()
                            alert(
                                `Veuillez copier le nouveau mot de passe : ${this.state.new_password}`
                            )
                        }}
                        close={() => {
                            this.setState({change_password: false})
                        }}
                    />
                ) : (
                    <></>
                )}
                {this.state.renew_licence ? (
                    <RenewLicence
                        onClose={() => {
                            this.setState({
                                renew_licence: !this.state.renew_licence,
                            })
                        }
                        }
                        onSubmit={this.groupedLicenceRenewal}
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}

function UserBooleanCell(props: {
    bool: boolean
    toggle_active: boolean
    user_id: number
    reload: () => void
}) {
    return (
        <TableCell
            sx={{
                color: props.bool ? 'text.primary' : 'text.disabled',
                py: 0,
            }}
        >
            {props.toggle_active ? (
                <Tooltip
                    title={props.bool ? 'Désactiver' : 'Activer'}
                    placement="right"
                >
                    <Button
                        onClick={async () => {
                            await UserApi.toggleActive(props.user_id)
                            props.reload()
                        }}
                        sx={{color: 'text.primary'}}
                    >
                        <b>{props.bool ? 'OUI' : 'NON'}</b>
                    </Button>
                </Tooltip>
            ) : (
                <b>{props.bool ? 'OUI' : 'NON'}</b>
            )}
        </TableCell>
    )
}

