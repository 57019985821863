import { Box, Paper } from '@mui/material'
import { Feature, Map, View } from 'ol'
import { Geometry, Polygon } from 'ol/geom'
import Draw, { createBox } from 'ol/interaction/Draw'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { transform, transformExtent } from 'ol/proj'
import { OSM, Vector as VectorSource } from 'ol/source'
import React from 'react'
import {
    CoordinatesSquare,
    getTilesConfiguration,
} from '../global_variables/tiles_config'

export interface CoordinatesPickerProps {
    current_location: CoordinatesSquare
    set_new_location: (s: CoordinatesSquare) => void
}

interface CoordinatesPickerState {
    map: Map
    source: VectorSource
    location: any
    current_location?: CoordinatesSquare
}

export class CoordinatesPicker extends React.Component<
    CoordinatesPickerProps,
    CoordinatesPickerState
> {
    constructor(props: CoordinatesPickerProps) {
        super(props)
        this.state = {
            map: new Map(),
            source: new VectorSource({ wrapX: false }),
            location: null,
        }
    }

    componentDidMount(): void {
        this.load()

        // TODO : renommer en INITIAL COORDINATES
        const SAMPLE_COORDS = [
            [
                this.props.current_location.min.lon,
                this.props.current_location.min.lat,
            ],
            [
                this.props.current_location.max.lon,
                this.props.current_location.max.lat,
            ],
        ]

        const ring = [
            [SAMPLE_COORDS[0][0], SAMPLE_COORDS[0][1]],
            [SAMPLE_COORDS[1][0], SAMPLE_COORDS[0][1]],
            [SAMPLE_COORDS[1][0], SAMPLE_COORDS[1][1]],
            [SAMPLE_COORDS[0][0], SAMPLE_COORDS[1][1]],
        ]
        let polygon = new Polygon([ring])
        this.state.source.addFeature(
            new Feature(polygon.transform('EPSG:4326', 'EPSG:3857'))
        )
    }

    async load() {
        try {
            const tiles_data = await getTilesConfiguration()

            const raster = new TileLayer({
                source: new OSM({ url: tiles_data.url }),
            })

            const vector = new VectorLayer({
                source: this.state.source,
            })

            const map = new Map({
                layers: [raster, vector],
                target: 'map',
                view: new View({
                    center: transform(
                        [
                            tiles_data.defaultCenter.lon,
                            tiles_data.defaultCenter.lat,
                        ],
                        'EPSG:4326',
                        'EPSG:3857'
                    ),
                    zoom: 6,
                    extent: transformExtent(
                        [
                            tiles_data.extent.min.lon,
                            tiles_data.extent.min.lat,
                            tiles_data.extent.max.lon,
                            tiles_data.extent.max.lat,
                        ],
                        'EPSG:4326',
                        'EPSG:3857'
                    ),
                }),
            })

            this.setState({ map: map })

            setTimeout(() => {
                this.addInteraction()
            }, 10)
        } catch (e) {
            console.error(e)
            alert('Echec du chargement des informations de cartographie...')
        }
    }

    addInteraction() {
        let geometryFunction
        geometryFunction = createBox()

        let draw = new Draw({
            source: this.state.source,
            type: 'Circle',
            geometryFunction: geometryFunction,
        })
        this.state.map.addInteraction(draw)

        draw.addEventListener('drawstart', () => {
            this.state.source.clear()
        })

        draw.addEventListener('drawend', () => {
            setTimeout(() => {
                this.getGeometryLocation()
            }, 10)
        })
    }

    // TODO : renommer plus cette fonction : setNewLocation par exemple
    getGeometryLocation() {
        let feature: Feature<Geometry>
        feature = new Feature()
        this.state.source.forEachFeature((f) => {
            feature = f
        })

        if (!feature) return

        const extent = feature.getProperties().geometry.getExtent()
        const location = transformExtent(extent, 'EPSG:3857', 'EPSG:4326')
        this.setState({ location: location })
        this.props.set_new_location({
            min: {
                lon: location[0],
                lat: location[1],
            },
            max: {
                lon: location[2],
                lat: location[3],
            },
        })
    }

    render() {
        return (
            <Box
                component={Paper}
                id="map"
                sx={{
                    width: 1,
                    height: 1,
                    border: 1,
                    borderColor: 'primary.main',
                }}
            ></Box>
        )
    }
}
