import { AlgosJavascript } from '../interfaces/algosJavascript_interface'
import { ApiClient } from './api_client'

export class AlgosJavascriptApi {
    /**
     * Récupère de la bas la liste des ALgos JS
     * @returns AlgosJS[]
     */
    static async listAlgosJS(): Promise<AlgosJavascript[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/algos_js/history')
        } catch (e) {
            throw new Error(
                `Echec de la récupération de la liste des algorithme JavaScript`
            )
        }
    }

    /**
     * Crée en base un nouvel Algo JS
     * @param algos_js
     */
    // TODO : Ne prendre en paramètre que le message et le contenu du nouvel algo
    static async createAlgosJS(algos_js: AlgosJavascript) {
        // TODO : supprimer ce try / catch
        try {
            // TODO : le return a-t-il un sens ? REMY => Permet la mise à jour automatique de la liste des algorithme JS sur l'interface... Il doit être possible de faire autrement en effet
            return await ApiClient.post('/algos_js/create', {
                body: JSON.stringify(algos_js),
            })
        } catch (e) {
            throw new Error(`Echec de la création de l'algorithme JavaScript`)
        }
    }

    /**
     * Supprime en base l'Algo JS
     * @param algos_js
     */
    static async deleteAlgosJS(algos_js: AlgosJavascript) {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.delete(`/algos_js/${algos_js.id}`)
        } catch (e) {
            throw new Error(
                `Echec de la suppression de l'algorithme JS d'id : ${algos_js.id}`
            )
        }
    }

    /**
     * Active ou désactive l'utilisation de l'algorithme en Beta
     * @param algos_js
     */
    static async toggleBetaUseAlgosJS(algos_js: AlgosJavascript): Promise<any> {
        const body = {
            id: algos_js.id,
            inUse: algos_js.inUse,
            betaUse: true,
        }

        // TODO : supprimer ce try / catch
        try {
            await ApiClient.put(`/algos_js/set_status`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec du changement de status Beta de l'algorithme JS d'id : ${algos_js.id}`
            )
        }
    }

    /**
     * Active ou désactive l'utilisation d'une version ds'algorithme
     * @param algos_js
     */
    static async toggleActiveAlgosJS(algos_js: AlgosJavascript): Promise<any> {
        const body = {
            id: algos_js.id,
            inUse: true,
            betaUse: algos_js.betaUse,
        }

        // TODO : supprimer ce try / catch
        try {
            await ApiClient.put(`/algos_js/set_status`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec du changement de status Actif de l'algorithme JS d'id : ${algos_js.id}`
            )
        }
    }
}
