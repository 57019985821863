import {Project} from "../../interfaces/project_interface";
import React from "react";
import {
    Box, Button, List, ListItem,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import {ProjectApi} from "../../api/projects_api";
import Icon from "@mdi/react";
import {mdiDelete, mdiEyeOutline} from "@mdi/js";
import {Link} from "react-router-dom";
import Areyousure from "../../components/Popup/Areyousure/Areyousure";


export interface ProjectsProps {
    current_user: number
}

interface ProjectState {
    project: Project[]
    action_user_id: number
    delete: boolean
}

export class Projects extends React.Component<ProjectsProps, ProjectState> {
    constructor(props: ProjectsProps) {
        super(props)
        this.state = {
            project: [],
            action_user_id: NaN,
            delete: false
        }
    }

    componentDidMount(): void {
        this.load().then()
    }

    async load() {
        try {
            const project = await ProjectApi.listUserProjects();
            this.setState({
                project: project,
            })
            console.log(project)
        } catch (e) {
            console.error(e)
            alert("Echec du chargement des projets")
        }
    }

    render() {
        return (
            <Box sx={{p: 5}}>
                <Box sx={{borderBottom: 1, mb: 2}}>
                    <h2>Mes projets</h2>
                </Box>
                <Box>
                    <TableContainer component={Paper} sx={{marginTop: 5}}>
                        <Table>
                            <TableHead
                                sx={{
                                    backdropFilter: 2,
                                    backgroundColor: 'primary.main',
                                }}
                            >
                                <TableRow>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Nom du projet
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Nom de la forêt
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff'}}>
                                        Commune
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                        Dernière mise à jour
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                        État
                                    </TableCell>
                                    <TableCell sx={{color: '#ffffff', textAlign: 'center'}}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!(this.state.project.length) ? (
                                    <TableRow sx={{color: '#ffffff'}}>
                                        <TableCell sx={{py: 2}}>
                                            Vous ne possedez aucun projet !
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.project
                                        .sort((a, b) => a.timeUpdate < b.timeUpdate ? 1 : -1)
                                        .map((project: any) => (
                                            <TableRow
                                                key={project.id}
                                            >
                                                <TableCell sx={{py: 2}}>
                                                    {project.data.name}
                                                </TableCell>
                                                <TableCell sx={{py: 2}}>
                                                    {project.data.forestName}
                                                </TableCell>
                                                <TableCell sx={{py: 2}}>
                                                    {project.data.commune}
                                                </TableCell>
                                                <TableCell sx={{py: 0, textAlign: 'center'}}>
                                                    {new Date(
                                                        project.timeUpdate * 1000
                                                    ).toLocaleDateString()}
                                                </TableCell>
                                                {(project.data.isClosed) ? (
                                                    <TableCell sx={{py: 2, color: '#ff4524', textAlign: 'center'}}>
                                                        Fermé
                                                    </TableCell>
                                                ) : (
                                                    <TableCell sx={{py: 2, textAlign: 'center'}}>
                                                        Ouvert
                                                    </TableCell>
                                                )}
                                                <TableCell sx={{py: 0, display: 'flex', justifyContent: 'center'}}>
                                                    <Box>
                                                        <List>
                                                            <ListItem sx={{p: 0, my: 1}}>
                                                                <Tooltip
                                                                    title="Supprimer"
                                                                    placement="right"
                                                                >
                                                                    <Button
                                                                        variant="outlined"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                action_user_id:
                                                                                project.id,
                                                                                delete: true,
                                                                            })
                                                                        }}
                                                                        sx={{p: 0, px: 1}}
                                                                        color="error"
                                                                    >
                                                                        <Icon
                                                                            path={mdiDelete}
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            </ListItem>
                                                            <ListItem sx={{p: 0, my: 1}}>
                                                                <Link
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    to={`${project.id}`}
                                                                >
                                                                    <Tooltip
                                                                        title="Détails"
                                                                        placement="right"
                                                                    >
                                                                        <Button
                                                                            variant="outlined"
                                                                            sx={{p: 0}}
                                                                        >
                                                                            <Icon
                                                                                path={
                                                                                    mdiEyeOutline
                                                                                }
                                                                            />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Link>
                                                            </ListItem>
                                                        </List>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>)))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {this.state.delete ? (
                        <Areyousure
                            message="supprimer un projet"
                            action={async () => {
                                await ProjectApi.deleteProjetUser(this.state.action_user_id)
                            }}
                            close={() => {
                                this.setState({delete: false})
                            }}
                            reload={() => this.load()}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        )
    }
}