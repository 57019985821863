import { FunderCategory } from '../interfaces/funder_interface'
import { ApiClient } from './api_client'

export class FunderApi {
    /**
     * Récupère la liste des Funder Categories
     * @returns FunderCategory[]
     */
    static async listFunderCategory(): Promise<FunderCategory[]> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/config/financeurs')
        } catch (e) {
            throw new Error(
                `Echec de la récupération de la liste des financeurs`
            )
        }
    }

    /**
     * Enregistre en base une nouvelle version de la liste des financeurs
     */
    static async replaceFunders(funders: FunderCategory[]) {
        // TODO : supprimer ce try / catch
        // TODO : supprimer cette variable inutile
        const body = funders
        try {
            return await ApiClient.post(`/config/financeurs`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(`Echec de la mise à jour des financeurs`)
        }
    }
}
