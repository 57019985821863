import logo from '../../assets/images/logo_bioclimsol.png'
import { AuthToken } from '../../api/token_api'

import { Alert, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import React from 'react'
import { UserApi } from '../../api/users_api'
import { ApiError } from '../../api/api_client'

export interface LoginProps {}

interface LoginState {
    email: string
    password: string
    error_message: string
}

export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props)
        this.state = {
            email: '',
            password: '',
            error_message: '',
        }
    }

    // TODO : intégrer cette fonction à loguser
    saveUser(authentification_response: any) {
        AuthToken.set(authentification_response.token)

        window.location.href = '/' // HINT : Fonctionne, explorer le mécanisme pour comprendre pourquoi
    }

    async logUser() {
        try {
            const authentification_response = await UserApi.loginWithPassword(
                this.state.email,
                this.state.password
            )
            this.saveUser(authentification_response)
        } catch (e) {
            // TODO : Exemple gestion des erreurs
            console.log(e)
            if (e instanceof ApiError) {
                // TODO : refactoriser avec un switch case
                if (e.code === 401) {
                    this.setState({
                        error_message:
                            "Accès refusé : vous n'êtes pas autorisé à accéder à cette ressource.",
                    })
                }
                if (e.code === 403 || e.code === 404) {
                    this.setState({
                        error_message:
                            "L'identifiant ou le mot de passe est incorrect",
                    })
                }
                if (e.code === 406) {
                    this.setState({
                        error_message:
                            "Désolé, vous ne pouvez pas accéder à votre compte car il a été désactivé. Veuillez contacter notre administrateur pour obtenir de l'aide.",
                    })
                }
                if (e.code === 409) {
                    this.setState({
                        error_message:
                            "Accès restreint : Ce formulaire est réservé aux utilisateurs standard. Veuillez vous authentifier en utilisant le bouton 'Accès Employé CNPF'.",
                    })
                }
                if (e.code === 412) {
                    this.setState({
                        error_message:
                            'La licence associée à ce compte est expirée. Veuillez nous contacter pour renouveler votre licence.',
                    })
                }
                if (e.code === 429) {
                    this.setState({
                        error_message:
                            'Trop de tentatives de connexion, veuillez réessayer ultérieurement.',
                    })
                }
            }
        }
    }

    async handleForgottenPassword() {
        const response = await UserApi.forgottenPassword()

        alert(
            `Veuillez contacter notre équipe de support à l'adresse mail suivante pour demander une réinitialisation de votre mot de passe : ${response.mail} `
        )
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    width: '100vw',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                id="login_background"
            >
                <CssBaseline />
                <Box
                    component={Paper}
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: 1,
                        mx: 85,
                        px: 5,
                        backdropFilter: 'blur(7px)',
                        WebkitBackdropFilter: 'blur(7px)',
                        backgroundColor: '#ffffff1f',
                        borderColor: '#ffffff31',
                        borderRadius: 5,
                        boxShadow: 3,
                    }}
                >
                    <img
                        src={logo}
                        alt="Logo de BioClimSol"
                        className="login_logo"
                    />
                    <Box
                        component="form"
                        onSubmit={(event) => {
                            event.preventDefault()
                            this.logUser()
                        }}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        {this.state.error_message ? (
                            <Alert variant="filled" severity="error">
                                {this.state.error_message}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(event) => {
                                this.setState({ email: event.target.value })
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& > fieldset': {
                                        borderColor: '#ffffffca',
                                    },
                                },
                                '& .MuiOutlinedInput-root:hover': {
                                    '& > fieldset': {
                                        borderColor: '#ffffff',
                                    },
                                },
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                    '& > fieldset': {
                                        borderColor: 'primary.main',
                                        boxShadow: 2,
                                    },
                                },
                                label: {
                                    color: '#ffffff',
                                },
                                input: {
                                    color: '#ffffff',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(event) => {
                                this.setState({ password: event.target.value })
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& > fieldset': {
                                        borderColor: '#ffffffca',
                                    },
                                },
                                '& .MuiOutlinedInput-root:hover': {
                                    '& > fieldset': {
                                        borderColor: '#ffffff',
                                    },
                                },
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                    '& > fieldset': {
                                        borderColor: 'primary.main',
                                        boxShadow: 2,
                                    },
                                },
                                label: {
                                    color: '#ffffff',
                                },
                                input: {
                                    color: '#ffffff',
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, color: '#FFFFFF' }}
                        >
                            se connecter
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link
                                    onClick={this.handleForgottenPassword}
                                    color="secondary"
                                    href="#"
                                    variant="body2"
                                >
                                    Mot de passe oublié ?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={UserApi.startOpenIdProtocol}
                    >
                        Accès employé CNPF
                    </Button>
                </Box>
            </Box>
        )
    }
}
