import { Button, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { AlgosChangelogApi } from '../../api/algos_changelog_api'
import { AlgosChangelog } from '../../interfaces/algoschangelog_interface'
import { AlgosChangelogTable } from './algos_changelog_table'

interface AlgosChangelogsProps {}

interface AlgosChangelogsState {
    algos_changelogs?: AlgosChangelog[]
}

export class AlgosChangelogs extends React.Component<
    AlgosChangelogsProps,
    AlgosChangelogsState
> {
    constructor(props: AlgosChangelogsProps) {
        super(props)
        this.state = {}

        this.load = this.load.bind(this)
        this.editLogs = this.editLogs.bind(this)
    }

    componentDidMount(): void {
        this.load()
        window.onbeforeunload = function () {
            return "Tout ce qui n'a pas été enregistré sera perdu. Êtes vous sûr de vouloir reloader cette page ?"
        }
    }

    async load() {
        // TODO : gérer les erreurs (try / catch)
        await AlgosChangelogApi.getAlgosChangelog().then((response) => {
            const res = response
            console.log(res)
            this.setState({ algos_changelogs: res })
        })
    }

    editLogs(logs: AlgosChangelog[]) {
        this.setState({ algos_changelogs: logs })
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                <Box sx={{ borderBottom: 1, mb: 2 }}>
                    <h2>Versions des algorithmes</h2>
                </Box>
                <Button
                    sx={{ mb: 2 }}
                    variant="outlined"
                    onClick={() => {
                        // TODO : déplacer dans unee fonction de classe
                        const date = new Date()
                        const algos_changelogs = this.state.algos_changelogs
                        algos_changelogs!.unshift({
                            version: '',
                            date: date.toLocaleString('fr-FR', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            }), // Remy : Changer Pour un date picker ?
                            // PHU 14/04 : OUI
                            // TODO : remplacer par un picker
                            content: '',
                        })
                        this.setState({ algos_changelogs: algos_changelogs })
                    }}
                >
                    Ajouter une entrée
                </Button>
                {this.state.algos_changelogs ? (
                    <AlgosChangelogTable
                        logs={this.state.algos_changelogs!}
                        load={this.load}
                        editLogs={(logs: AlgosChangelog[]) => {
                            this.editLogs(logs)
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            height: 'calc(100vh - 200px)',
                            width: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}
