import { CGU } from '../interfaces/cgu_interface'
import { ApiClient } from './api_client'

export class CguApi {
    /**
     * Récupère la dernière CGU enregistré dans la base de donnée
     * @returns CGU { id: number, content: string }
     */
    static async getLatestCGU(): Promise<CGU> {
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.get('/config/cgu')
        } catch (e) {
            throw new Error(
                `Echec du chargement des dernières conditions générales d'utilisation`
            )
        }
    }

    /**
     * Enregistre une nouvelle version des CGU
     * @param cgu CGU
     */
    static async createCGUs(cgu: CGU) {
        // TODO : supprimer cette variable (body)
        const body = cgu
        // TODO : supprimer ce try / catch
        try {
            await ApiClient.post('/config/cgu', {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec de la création des nouvelles conditions générales d'utilisation`
            )
        }
    }
}
