import { AuthToken } from './token_api'
import { APK } from '../interfaces/apk_interface'
import { TilesConfiguration } from '../global_variables/tiles_config'
import { get_restricted_url } from '../global_variables/backend_url_api'
import { ApiClient } from './api_client'

export class GeneralFunctionsApi {
    /**
     * Récupère une liste d'objets avec un paramère -id-
     * @param list
     * @returns la liste dans lordre des id croissant
     */
    // TODO : Cette fonction peut être facilement contournée à l'aide de sort.
    // il faudrait envisager sa suppression
    static SortByID(list: any[]): any[] {
        return list.sort((a, b) => {
            if (
                a.hasOwnProperty('id') &&
                typeof a.id === 'number' &&
                b.hasOwnProperty('id') &&
                typeof b.id === 'number'
            ) {
                return a.id - b.id
            }
            return 0
        })
    }

    /**
     * Récupère une liste d'objets avec un paramère -id-
     * @param list
     * @returns la liste dans lordre des id décroissant
     */
    // TODO : idem que pour la fonction juste au-dessus, cette fonction peut être supprimée
    static SortByIDReverse(list: any[]): any[] {
        return list.sort((a, b) => {
            if (
                a.hasOwnProperty('id') &&
                typeof a.id === 'number' &&
                b.hasOwnProperty('id') &&
                typeof b.id === 'number'
            ) {
                return b.id - a.id
            }
            return 0
        })
    }

    /**
     * Récupération de la configuration des tuiles
     *
     * @returns Les données nécessaires pour la sélection d'une zone OpenLayers
     */
    static async fetchTilesUrl(): Promise<TilesConfiguration> {
        // TODO : supprimer le recours direct à fetch
        const response = await fetch(
            `${get_restricted_url()}/config/tile_server`,
            {
                headers: {
                    Authorization: AuthToken.get(),
                },
            }
        )

        // TODO : refacto. Supprimer le else
        if (response.status === 200) {
            return await response.json()
        } else {
            throw new Error(
                'Echec du téléchargement des informations OpenLayers'
            )
        }
    }

    /**
     * Récupère un fichier et renvoie sa conversion en base64
     * @param file - Fichier
     * @returns - Fichier converti en base64
     */
    static async convertToBase64(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    /**
     * Interverti deux elements d'un array et renvoie l'array modifié
     * @param first_index - number
     * @param second_index - number
     * @param array
     * @returns array switched
     */
    // TODO : déplacer cette fonction dans un fichier d'utilitaires; le dossier "api"
    // ne doit contenir que des fonctions d'interrogation de l'API
    static SwitchEntries(
        first_index: number,
        second_index: number,
        array: any[]
    ): any[] {
        const temp = array[first_index]
        array[first_index] = array[second_index]
        array[second_index] = temp
        return array
    }

    /**
     * @returns APK - données de versions de l'APK et Url de téléchargement
     */
    static async getApkData(): Promise<APK> {
        try {
            return await ApiClient.get('/config/app_release', {
                restricted: false,
            })
        } catch (e) {
            throw new Error(
                `Echec du téléchargement des données de version de l'APK`
            )
        }
    }
}
