import { Structure } from '../interfaces/structure_interface'
import { ApiClient } from './api_client'

export class StructureApi {
    /**
     * Récupération de la liste des structures
     *
     * @param token
     * @returns liste des structures
     */
    static async listStructures(): Promise<Structure[]> {
        return await ApiClient.get('/structures')
    }

    /**
     * Mise à jour d'une structure en base
     */
    static async editStructure(name: string, id: number) {
        const body = {
            name: name,
        }
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.put(`/structures/${id}`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(`Echec d'édition de la structure id : ${id}`)
        }
    }

    /**
     * Supprime une structure en base
     */
    static async deleteStructure(id: number) {
        return await ApiClient.delete(`/structures/${id}`)
    }

    /**
     * prends en paramètres l'id de la structure et le token. Le fetch renvoie le nombre d'Users associés à cette structure
     * si la réponse du back-end est supérieur à zéro, la fonction retourne false
     * @param token
     * @param id
     * @returns
     */
    // TODO : cette fonction semble inutilisée. Soit on la corrige et on l'utilise,
    // soit on la supprime
    static async isDeletable(id: number): Promise<boolean> {
        // TODO : supprimer ce try / catch
        try {
            return (await ApiClient.get(
                `/web/structures/is_destroyable/${id}`
            )) > 0
                ? true
                : false
        } catch (e) {
            throw new Error(
                `Echec de récupération du status de la structure id : ${id}`
            )
        }
    }

    /**
     * Création d'une nouvelle structure
     */
    // TODO : Renommer en createStructure
    static async saveStructure(name: string) {
        const body = {
            name: name,
        }
        // TODO : supprimer ce try / catch
        try {
            return await ApiClient.post(`/structures`, {
                body: JSON.stringify(body),
            })
        } catch (e) {
            throw new Error(
                `Echec de création de la structure de nom : ${name}`
            )
        }
    }
}
