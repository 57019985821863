import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React from 'react'
import { GeneralFunctionsApi } from '../../api/general_functions_api'
import { StructureApi } from '../../api/structures_api'
import Areyousure from '../../components/Popup/Areyousure/Areyousure'
import TextInputModale from '../../components/Popup/TextInputModale/TextInputModale'
import { Structure } from '../../interfaces/structure_interface'
import StructureRow from './structure_row'
import { ApiError } from '../../api/api_client'

export interface StructuresProps {}

interface Structurestate {
    structures?: Structure[]
    delete: boolean
    save: boolean
    newStructure: boolean
    current_structure_id: number
    current_structure_name: string
    cantPerform: boolean
    canceled: number
    reloaded: number
    error_message: string
}

export class Structures extends React.Component<
    StructuresProps,
    Structurestate
> {
    constructor(props: StructuresProps) {
        super(props)
        this.state = {
            delete: false,
            save: false,
            newStructure: false,
            current_structure_id: NaN,
            current_structure_name: '',
            cantPerform: false,
            canceled: 0,
            reloaded: 0,
            error_message: '',
        }
        this.load = this.load.bind(this)
        this.askForSave = this.askForSave.bind(this)
        this.askForDelete = this.askForDelete.bind(this)
        this.performDelete = this.performDelete.bind(this)
        this.performSave = this.performSave.bind(this)
    }

    componentDidMount(): void {
        this.load()
    }

    async load() {
        try {
            const structures = await StructureApi.listStructures()
            this.setState({ structures: structures })
        } catch (e) {
            console.error(e)
            alert('Echec du chargement de la liste des structures')
        }
    }

    async performSave() {
        // TODO : ajouter la gestion des erreurs (try / catch)
        await StructureApi.editStructure(
            this.state.current_structure_name,
            this.state.current_structure_id
        )
        await this.load()
    }

    async performDelete() {
        try {
            await StructureApi.deleteStructure(this.state.current_structure_id)
        } catch (e) {
            if (e instanceof ApiError) {
                this.setState({
                    error_message:
                        "La suppression de cet élément n'est pas autorisée.",
                })
            }
        }
        await this.load()
    }

    askForSave(structure_id: number, structure_name: string) {
        this.setState({
            current_structure_id: structure_id,
            current_structure_name: structure_name,
            save: true,
        })
    }

    // TODO : Refactoriser ce système de confirmation ?
    async askForDelete(structure: Structure) {
        this.setState({
            current_structure_id: structure.id,
            current_structure_name: structure.name,
            // cantPerform: await StructureClass.isDeletable(structure.id),
        })
        this.setState({ delete: true })
    }

    // TODO : Déplacer ce contrôle côté back-end également ?
    async createStructure(name: string) {
        if (name.length < 1) {
            alert("La structure n'est pas nommée")
            return
        }
        StructureApi.saveStructure(name)
    }

    render() {
        return (
            <Box sx={{ p: 5 }}>
                {this.state.error_message ? (
                    <Alert
                        variant="filled"
                        severity="error"
                        onClose={() => {
                            this.setState({ error_message: '' })
                        }}
                    >
                        {this.state.error_message}
                    </Alert>
                ) : (
                    <></>
                )}

                <Box sx={{ borderBottom: 1, mb: 2 }}>
                    <h2>Gestion des structures</h2>
                </Box>
                <div>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            this.setState({
                                newStructure: !this.state.newStructure,
                            })
                        }}
                    >
                        <span>Ajouter une structure</span>
                    </Button>
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 1,
                    }}
                >
                    {this.state.structures ? (
                        <TableContainer component={Paper} sx={{ my: 4 }}>
                            <Table aria-label="structure list table">
                                <TableHead
                                    sx={{
                                        backdropFilter: 2,
                                        backgroundColor: 'primary.main',
                                    }}
                                >
                                    <TableRow>
                                        <TableCell
                                            sx={{ color: '#ffffff' }}
                                            align="left"
                                        >
                                            #
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Nom
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Editer
                                        </TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>
                                            Supprimer
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {GeneralFunctionsApi.SortByID(
                                        this.state.structures
                                    )?.map((element: any) => (
                                        <StructureRow
                                            structure={element}
                                            key={element.id}
                                            askForSave={this.askForSave}
                                            askForDelete={this.askForDelete}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box
                            sx={{
                                height: 'calc(100vh - 200px)',
                                width: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {this.state.delete ? (
                        <Areyousure
                            message={
                                <span>
                                    {' '}
                                    supprimer la structure{' '}
                                    <span>
                                        {this.state.current_structure_name}
                                    </span>
                                    .
                                    {this.state.cantPerform ? (
                                        // TODO Rachida supprimer cantPerfom ou fix it

                                        <>
                                            <br />
                                            <br />

                                            <Typography color="secondary">
                                                Action impossible car des
                                                utilisateurs sont encore
                                                rattachés à la structure
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            }
                            close={() => {
                                this.setState({ delete: !this.state.delete })
                            }}
                            action={this.performDelete}
                            cantPerform={this.state.cantPerform}
                            reload={this.load}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.save ? (
                        <Areyousure
                            message={
                                <span>
                                    renommer la structure en{' '}
                                    <span>
                                        {this.state.current_structure_name}
                                    </span>
                                </span>
                            }
                            close={async () => {
                                await this.load()
                                this.setState({ save: false })
                            }}
                            action={this.performSave}
                            reload={this.load}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.newStructure ? (
                        <TextInputModale
                            message={'Nouvelle Structure'}
                            close={() => {
                                this.setState({
                                    newStructure: !this.state.newStructure,
                                })
                                this.load()
                            }}
                            reload={this.load}
                            action={this.createStructure}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        )
    }
}
