// TODO : Chercher dans le code les appels à get_restricted_url() qui devraient être transformés en get_unrestricted_url()

// TODO : faire en sorte que get_restricted_url() et get_unrestricted_url() appelle une même fonction
// get_base_admin_api_url() et ajoute un suffix à l'URL si nécessaire pour n'avoir  à définir qu'une
// seule variable d'environnement

/**
 * Récupération de l'URL d'interrogation du backend restreinte (nécessite un token valide)
 */
export function get_restricted_url(): string {
    let backend: string | undefined =
        process.env.REACT_APP_BIOCLIMSOL_BACKEND_RESTRICTED_URL
    if (backend === undefined) {
        throw new Error(
            "La variable d'environnement REACT_APP_BIOCLIMSOL_BACKEND_RESTRICTED_URL est undefined!"
        )
    }
    return backend
}

/**
 * Récupération de l'URL d'interrogation du backend non restreinte
 */
export function get_unrestricted_url(): string {
    let backend: string | undefined =
        process.env.REACT_APP_BIOCLIMSOL_BACKEND_UNRESTRICTED_URL
    if (backend === undefined) {
        throw new Error(
            "La variable d'environnement REACT_APP_BIOCLIMSOL_BACKEND_UNRESTRICTED_URL est undefined!"
        )
    }
    return backend
}
